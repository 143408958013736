@import "src/assets/scss/variables";

.chip-input-component{
  .chip-input-container {
    position: relative;
    border-bottom: 1px solid var(--gray-300, #D4D4D8);
    padding: 6px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-height: 80px;
    overflow-y: scroll;

    .ant-input{
      border: none;
      outline: none;
      box-shadow: none !important;
    }
    .ant-input:focus {
      outline: none;
      box-shadow: none !important;
    }

    .email-chip{
      border-color: $chip-color !important;
    }

    .email-cc-container{
      position: absolute;
      top: 0;
      right: 0;
      background: $background-color;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 12px;
      .email-cc{
        margin: 0 10px;
        color:$black;
        font-weight: 500;
        &:hover{
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
}

.chip-input-container .ant-tag {
  margin: 2px;
}

.chip-input-container .ant-input {
  border: none;
  box-shadow: none;
  flex-grow: 1;
  min-width: 100px;
}

}
