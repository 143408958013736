.hcp-attendance-component {
  .header-text {
    color: rgba(0, 0, 0, 0.74);
    font-size: 14px;
    font-weight: 400;
  }
}

.hcp-attendance-table-component{
  .ant-table-body{
    min-height: 43vh !important;
    max-height: 43vh !important;
  }
}

.green {
  color: #069D30;
  margin-left: 10px;
}

.yellow {
  color: #CE8B25;
  margin-left: 10px;
}

.red {
  color: #F00;
  margin-left: 10px;
}
.edit-button-score{
  color: #0C80E3;
}