@import "src/assets/scss/common";

.group-add {
	.sms-blast-icon {
		color: $accent-color;
	}
	.sms-blast {
		color: $accent-color;
		margin-left: 10px;
	}
	.message-wrapper {
		cursor: pointer;
		border: 1px solid $accent-color;
		border-radius: 10px;
		width: 150px;
		padding: 0.15rem 0;
		justify-content: center;
		align-items: center;
		background-color: #f6fefb;
	}
	.MuiPaper-elevation1 {
		box-shadow: none !important;
	}
	.filter-icon {
		font-size: 2rem;
		color: $light-black;
	}
	.text-right {
		text-align: right !important;
		padding-right: 20px !important;
	}
	.last-row {
		border-radius: 0px 5px 5px 0px;
	}
	.first-row {
		border-radius: 5px 0px 0px 5px;
	}
	.group-list-table{
		.mat-td-created-at{
			min-width: 100px;
			max-width: 100px;
		  }
	  
		  .mat-td-title{
			min-width: 250px;
			max-width: 250px;
		  }
	  
		  .mat-td-members-count{
			min-width: 100px;
			max-width: 100px;
		  }
	  
		  .mat-td-sms-blast{
			min-width: 150px;
			max-width: 150px;
		  }
	  
		  .mat-td-actions{
			z-index: 99;
			min-width: 120px;
			max-width: 120px;
		  }
	}
}
