
.file-picker-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 1px dashed #B8B8B8;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;

  &.drag-active {
    box-shadow: 2px 2px 10px 5px #D9D9D9;
  }

  .file-dnd-icon {
    color: #CFDBDA;
  }

  .file-dnd-title {
    margin: 15px 0 0;
    font-size: 1.1429rem;
    line-height: 24px;

    .file-dnd-title__dnd {
      color: #212B36;
    }

    .file-dnd-title__select {
      color: #0DB9D8;
      text-decoration: underline;
    }
  }

  .file-dnd-sub-title {
    margin-top: 6px;
    font-size: 16px;
    line-height: 24px;
    color: #212B36;
  }

  .accepted-files-type-text {
    margin-top: 8px;
    color: #9EA2A8;
    font-size: 1.1429rem;
    line-height: 24px;
  }

  .file-dnd-selected-files-details {
    margin: 10px 0;
  }

}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-box {
  width: 100%;
  max-width: 600px;
  height: 200px;
  border: 3px dashed  #41d6c3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;

  &:hover {
    border-color:  #41d6c3;
  }

  .upload-icon {
    font-size: 32px;
    color: #41d6c3;
    margin-top: 12px;
  }

  .upload-title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  .upload-description {
    font-size: 14px;
    color: #6c757d;
  }
}
