@import "src/assets/scss/variables";
.applicant-menu-list-component{
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .applicant-menu-item{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: inherit;
    transition: background-color 0.3s linear;

    &:hover{
      background:$chip-color;
    }
  }
}