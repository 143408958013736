$primary-color: #10c4d3;
$accent-color: #0c80e3;

.shift-filters {
  .capitalize {
    text-transform: capitalize;
  }


  
  .loading-text-wrapper {
    text-align: center;
    span {
      display: inline-block;
      color: gray;
    }
  }
  .form-field-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .form-field-wrapper>* {
    flex: 1 1 10px;
}

  .custom-chips-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    .custom-chips {
      gap: 5px;

      .MuiChip-root {
        background: #e3fff4;
      }

      .MuiChip-label {
        color: $accent-color;
        // text-transform: capitalize;
      }

      .MuiChip-deleteIcon {
        color: $accent-color;
      }
    }
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }
  .react-datepicker-popper {
    z-index: 999 !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-bottom: 1rem;
  }

  .react-datepicker__day-name {
    color: #0c80e3;
    font-size: 14px;
    font-weight: bold;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range .react-datepicker__header {
    background-color: #0c80e3 !important;
  }

  .custom-input {
    cursor: pointer;
    font-size: 16px;
    padding: 0.25rem 0.8rem;
    width: 100%;
    height: 2.6rem;
    border: 0.5px solid lightgray;
    border-radius: 4px;
  }

  .date-icon {
    float: right;
    margin-right: 6px;
    margin-top: -2.1rem;
    position: relative;
    z-index: 999;
  }

  .custom-input:focus {
    outline: none;
  }

  .clear-all-filters {
    cursor: pointer;
    margin-left: 0.5rem;
    font-size: small;
  }
  .show-filter {
    display: inline-block;
  }
  .hide-filter {
    display: none;
  }
}
