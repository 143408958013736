@import "./assets/scss/common";

.App {
  .page-loading::before,
  .page-loading::after {
    position: fixed;
    z-index: 3000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .page-loading::before {
    content: '';
    background-color: $background-color;
  }

  .page-loading::after {
    font-family: 'Rubik', Helvetica, sans-serif;
    content: 'Loading...';
    text-align: center;
    white-space: pre;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.04rem;
    color: #10c4d3;
    opacity: 1;
    animation: animation 1s alternate infinite;
  }

  @keyframes animation {
    to {
      opacity: 0.3;
    }
  }

}

.app_version_wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  color: #AAAAAA;
}
