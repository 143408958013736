@import "src/assets/scss/variables";

.shift-custom-input{
  cursor: pointer;
  font-size: 14px;
  padding: 0.5rem 0.8rem;
  width: 100%;
  height: 3.5rem;
  border: 1px solid #C4c4c4;
  border-radius: 4px;
  z-index: 9999;
  display: flex;
  align-items: center;
  font-family: $font-family;
}

.show-filter {
  display: inline-block;
}
.hide-filter {
  display: none;
}