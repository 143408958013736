.applicant-bulk-upload-component{
  .job-header{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .applicant-empty-box{
    height: 70vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: #0c0b0b;
    border: 1px solid #D2D2D2;
    border-radius: 10px;
  }
}