@import "src/assets/scss/common";


.recover-shifts-screen{
    .recover-shifts-title{
        margin: 0;
    }

    .recover-shifts-subtitle{
     color: $primary-color;   
    }

    .MuiTypography-body1{
        color: $light-black !important;
    }

    


}