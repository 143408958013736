@import "src/assets/scss/variables";

.file-upload-component{
  display: flex;
  align-items: center;
  gap: 16px;

  .file-image-wrapper{
    position: relative;
    width:80px;
    height: 80px;

    .file-preview{
      display: flex;
      justify-content: center;
      align-items: center;
      color:#aaa;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: #f0f0f0;
      font-size: 14px;
    }
  }

  .file-upload-btn-wrapper{

  }
}