.more-fields-drawer-component{
    position: relative;
    height: calc(100vh - 90px);

  .more-fields-search{
    width: 100%;
    margin-bottom: 20px;
  }

  .more-fields-option-wrapper{
    max-height: 550px;
    overflow-y: scroll;
  }

  .more-fields-btn-wrapper{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .more-fields-btn{
      width: 100%;
      text-transform: capitalize;
    }
  }
}