.approved-hcps-list {
	.shifts-requirment-approved-list-table {
		.mat-td-hcp-rate {
			min-width: 150px;
			max-width: 150px;
		}

		.mat-td-hcp-name {
			min-width: 250px;
			max-width: 250px;
		}

		.mat-td-approved-name {
			min-width: 250px;
			max-width: 250px;
		}

		.mat-td-created-at {
			min-width: 160px;
			max-width: 160px;
		}

		.mat-td-hcp-rate {
			min-width: 150px;
			max-width: 150px;
		}

		.mat-td-actions {
			min-width: 120px;
			max-width: 120px;
		}
	}
}
