.quill {
  height: 100%;
  border-bottom: 1px solid var(--gray-300, #D4D4D8);

  .ql-toolbar {
    border: none !important;
    border-bottom: 1px solid #DADADA !important;

    .ql-snow {
      border: none !important;
    }
  }

  .ql-container {
    border: none !important;
    height: 100%;
    overflow-y: scroll;
    color: #0c0b0b;
  }
}
