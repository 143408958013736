.add-container {

  .minor {
    width: 50%;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid #ffffff;
    text-align: left;
  }

  .exp-add-action {
    margin: 3rem 0;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
