
.generate-component {
  .rate-color {
    color: green;
  }
}

.selected-text {
  color: #929292;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
}

//.MuiMenu-list{
//  max-height: 300px;
//}


.closed {
  color: #00da16 !important;
}

.pending {
  color: #454545 !important;
}

.complete {
  color: #0c80e3 !important;
}

.in_progress {
  color: #ffa800 !important;
}

.cancelled {
  color: #DE1C4E !important;
}