@import "src/assets/scss/common";

.pending-list-screen {
	margin: auto;
	text-align: center;
    width: calc(100vw - 265px);

    .pending-table-wrapper{
      .ant-table-body{
        min-height: 56vh !important;
        max-height: 56vh !important;
      }
    }

	.disabled-link{
		pointer-events: none;
	}

  .actions {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
  }

  .pending-shift-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
  }
  .actions-table{
    display: flex;
    align-items: center;
    justify-content: center;
  }

	.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
		padding: 2px !important;
	}

	.MuiPaper-elevation1 {
		box-shadow: none !important;
	}

	.filter-icon {
		font-size: 2rem;
		color: $light-black;
	}
  
	.captalize{
		text-transform: capitalize;
		font-weight: 500;
	}

	.cancelled {
		color: #f10000 !important;
	}

	.closed {
		color: #00da16 !important;
	}
	.pending {
		color: #454545 !important;
	}
	.complete {
		color: #0c80e3 !important;
	}
	.in_progress {
		color: #ffa800 !important;
	}
	.add-icon {
    color: green;
  }
  .delete-icon {
    color: #f10000 !important;
  }
  
  .approved {
    color: #0c80e3 !important;
  }
  .view-details-link {
    text-decoration: underline;
    color: $primary-color !important;
    cursor: pointer;
  }
  .shift-pending-list-table {

    .mat-td-checkbox {
      min-width: 80px;
      max-width: 80px;
    }
    .mat-td-applied-on {
      min-width: 120px;
      max-width: 120px;
    }
    .mat-td-hcp-name {
      min-width: 200px;
      max-width: 200px;
    }
  
    .mat-td-facility-name {
      min-width: 200px;
      max-width: 200px;
    }
  
    .mat-td-shift-date {
      min-width: 120px;
      max-width: 120px;
    }
    .mat-td-status {
      min-width: 100px;
      max-width: 100px;
    }
    .mat-td-approval {
      min-width: 90px;
      max-width: 90px;
    }
    .mat-td-shift-type {
      min-width: 80px;
      max-width: 80px;
    }
  
    .mat-td-hcp-type {
      min-width: 100px;
      max-width: 100px;
    }
  
    .mat-td-actions {
			z-index: 99;
      min-width: 300px;
      max-width: 300px;
    }
  }
  
}




