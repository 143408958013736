.add-document-drawer {
  :where(.css-dev-only-do-not-override-142vneq).ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #41D6C3 !important;
      border-color: #41D6C3 !important;
    }
  }

  &__search-input {
    margin-bottom: 16px;
  }

  &__list-container {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 16px;
    overflow-y: auto;
  }

  &__checkbox-item {
    display: flex;
    align-items: center;
    line-height: 1.5;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__checkbox-label {
    margin-left: 8px;
    font-size: 14px;
    flex: 1;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    text-align: right;
  }

}

.add-document-drawer__footer {
  .drawer-apply-button {
    background-color: #41D6C3;
    border: none;
    border-radius: 6px;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    padding: 0 20px 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #32b9ab !important;
    }
  }

  .drawer-deselect-all-button {
    background: none;
    border: none;
    color: red;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;

    &:hover {
      background: none !important;
      border: none;
      color: red !important;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}
