.required-documents-layout {
  &__section {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__section-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__tag {
    background-color: #E6F4FF;
    border: 1px solid #91CAFF;
    padding: 1px 8px;
    font-size: 12px;
    color: #1677FF;
  }

  &__no-selection {
    font-size: 14px;
    color: #8c8c8c;
  }

  &__add-button {
    font-size: 14px;
    color: #41D6C3;
    font-weight: 500;

    &:hover {
      color: #41D6C3;
    }

    span {
      color: #41D6C3;
    }
  }
}
