.staff-search-field{
  width: 450px !important;
}
.job-list-content{
  border: 1px solid #D2D2D2;
  padding-left: 10px;
  border-radius: 5px;
  height: 350px;
  overflow-y: auto;
  .MuiFormControlLabel-label{
    color: #0c0b0b !important;
  }
}