
.edit-generated-invoice {
  .MuiOutlinedInput-input {
    padding: 10.5px 6px;
  }

  .MuiTableContainer-root {
    .MuiPaper-elevation1 {
      box-shadow: 0px 3px 20px 0px red;
    }
  }
}

.timesheet-edit-dialog {
  .MuiOutlinedInput-input {
    padding: 10.5px 6px;
  }
}

.check-payable-edit-container {
  .MuiOutlinedInput-input {
    padding: 10.5px 6px;
  }
}


.custom-paper {
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.10) !important;
  border: none !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid #FFFFFF !important;
}

.edit-invoice-button {
  width: 112px;
  margin-left: 45%;
}