@import "src/assets/scss/common";

.pending-shiftsrequirment-view{
    max-width: calc(100vw - 80px) !important;

	@include md() {
        max-width: calc(100vw - 270px) !important;
	}

    p{
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        text-align: left;
    }
    .circular-progress{
        margin: auto;
    }
    .facility-approval-btn{
        margin:20px auto 0px auto;
        text-align: center;
    }
    th{
        color: $primary-color;
        font-weight: 480;
        font-size: medium;
    }
    th,
    td {
      text-align: left;
      padding:5px 10px;
      width: 16%;
    }
    .status-title{
        margin:auto;
        margin-right: 10px;
        text-transform: capitalize;
        margin-bottom: 10px;
        color: $light-black;
    }
    .status{
        color: $primary-color !important;
    }
    .file-actions{
        color: $primary-color !important;
        margin-top: 0px;
        text-decoration: underline;
        cursor: pointer;
    }
    .custom-border {
		padding: 0 3rem 2rem 2rem;
		border-radius: 0.5rem;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}
    .file-icon{
		font-size: 6rem;
		color: #41d5c2;
	}
    .facility-details{
        justify-content: space-between;
        padding: 10px 20px;
        .button{
           text-align: end;
        }
    }
    .hcp-name-type {
		margin-left: 20px;
		justify-content: center;
		h2 {
			margin-bottom: 0px;
            width:100%;
		}
		p {
			margin-top: 0px;
		}
	}
    .actions-wrapper{
        margin: auto;
    }
    .hcp-details{  
        flex-wrap: wrap;

        h4{
            color:$text-light ;
        }
        p{
            margin-bottom: 0px;
            color:$light-black;
        }
    }
    .shift-name-requested{
        justify-content: space-between;
        .requested-on-wrapper{
         text-align: center;
         p{
             margin-top: 18px;
         }
        }
    }
    h2{
        color:$light-black;
        font-weight: 470;
    }
    p{
        color:$text-light;
    }
    h3{
        color:$light-black;
        font-weight: 470;
    }
    .flex-1{
        flex:1px;
        max-width: 22%;
    }
    .shift-details{
        p{
            font-size: 14px;
            color:$light-black;
        }
        h3{
            color:$light-black;
        }
    }
    .hcp-photo-details-wrapper {
		margin: auto;
		justify-content: space-between;
		padding-bottom: 15px !important;
		.ratings {
			text-align: center;
			h4 {
				color: $text-light;
				margin-bottom: 0px;
			}
			p {
				color: $primary-color;
				font-size: 20px;
				margin-top: 0px;
			}
		}
	}

    .rejection-wrapper{
        .rejection-header{
            color: $primary-color;
        }
    }
}