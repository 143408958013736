.facility-notes-component{
.notes-heading{
  font-size: 18px;
  color: black;
  font-weight: 600;
}
  .notes-panel{
    margin-top: 20px;
    color: #2C2C2C;
    padding: 10px;
    .notes-author{
      margin-top: 10px;
      font-size: 10px;
      color: #908f8f;
      font-weight: 400;
    }
  }
  .MuiInputBase-inputMultiline {
    border: none !important;
  }
}