@import "../../../assets/scss/common";
@import "../../../assets/scss/variables";
//
//.auth-layout {
//  height: 100vh;
//  overflow: auto;
//
//  .logo-holder {
//    position: absolute;
//    top: 57px;
//    left: 53px;
//    color: white;
//  }
//
//  .branding-wrapper {
//    position: absolute;
//    top: 20px;
//    right: 30px;
//    color: var(--Vars-Colors-Table-Background, #FFF);
//    font-size: 19.592px;
//    font-style: normal;
//    font-weight: 600;
//    line-height: normal;
//  }
//
//  .info-text-wrapper {
//    position: absolute;
//    top: 65%;
//    left: 43px;
//    color: #FFF;
//    font-family: "Plus Jakarta Sans", sans-serif;
//    font-size: 49.246px;
//    font-style: italic;
//    font-weight: 800;
//    line-height: normal;
//  }
//
//  img {
//    width: auto;
//    height: 42px;
//  }
//
//
//  .no-repeat-background {
//    background-repeat: no-repeat;
//    background-position: center;
//    background-size: cover;
//    height: 100vh;
//    width: 100%;
//    display: flex;
//    justify-content: center;
//  }
//
//  .main-auth-holder {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    margin-left: 50%;
//
//
//    .screen-auth {
//      min-width: 400px;
//      max-width: 400px;
//      padding: 16px 24px;
//      border: 1px solid #E4E4E4;
//      background: #FFF;
//      box-shadow: 0px 4px 20.1px 2px rgba(223, 223, 223, 0.25);
//      border-radius: 20px;
//      background: rgba(251, 254, 255, 0.70);
//      backdrop-filter: blur(11.606217384338379px);
//    }
//  }
//
//
//}

.auth-layout{
  height: 100vh;
  overflow: hidden;

  .auth-layout-wrapper{
    display: flex;

    .auth-poster-wrapper{
      flex: 1;
      height: 100vh;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F6F6F6;
      background-repeat: no-repeat;
      background-position: right;

      .auth-poster-image-wrapper{
        position: absolute;
        width: 121px;
        top: 20px;
        left: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        .auth-poster-logo{

        }
        .auth-logo-text{
          font-size: 20px;
          font-weight: 800;
        }
      }

      .auth-poster-card{
        position: absolute;
        background-color: #FFFFFF;
        border-radius: 12px;
        bottom: 30px;
        width: 420px;
        display: flex;
        flex-direction: column;
        padding: 16px;
        box-shadow: 0 4px 25.2px 0 #00000012;

        .auth-poster-header{
          font-size: 20px;
          font-weight: 400;
          color: #535B63;
          margin-bottom: 10px;

          .auth-poster-header-highlight{
            color: #1570EF;
          }
        }
        .auth-poster-subheader{
          color:$text-light;
          font-size: 14px;
        }
      }
    }

    .auth-content-wrapper{
      flex: 2;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;


      .auth-main-wrapper{
        display: flex;
        flex-direction: column;

        .auth-welcome-text{
          font-size: 24px;
          font-weight: 600;
          color: #192938;
          text-align: center;
          line-height: 29.05px;
          margin-bottom: 15px;
        }
        .auth-welcome-subtext{
          color: $text-light;
          text-align: center;
        }

        .auth-main-content-wrapper{
          width: 500px;
          margin:0 auto;
        }
      }
    }
  }
}
