@import "src/assets/scss/common";
@import "src/assets/scss/variables";

.forgotPassword-screen {
  margin-top: 30px;

  .email-wrapper {
    z-index: 0;
  }

  .form-holder .MuiFormHelperText-root.Mui-error {
    top: 45px !important;
  }

  .change-email-wrapper {
    position: absolute;
    right: 5px;
    top: -8px;
    font-size: 12px;
    color: $primary-color;
    outline: none;
    font-weight: 400;
    cursor: pointer;
    opacity: 0.9;
    text-decoration: none;
    z-index: 2;

    &:hover {
      opacity: 1;
    }
  }

  .form-link {
    text-align: center;

    .back-to-login-link {
      color: $black;
      font-weight: bold;
      text-decoration: none !important;
    }
  }

  .MuiInputBase-input {
    color: black !important;
  }

  .eye_btn_wrapper {
    position: absolute;
    top: 38px;
    right: 20px;
    z-index: 2;

    Button {
      padding: 0px 0px !important;
    }
  }

  .auth-header {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  .auth-subheader{
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $text-light;
  }

  .form-label {
    color: $text-light;
    font-size: 14px;
  }
  .reset-form-label{
    text-align: center;
    color: $text-light;
    font-size: 14px;
  }

  .loginFormHolder {
    margin: 20px;
  }

  .forgot-password-holder {
    text-align: center;
  }

  .btns-holder {
    flex-direction: row;
    justify-content: center;
    flex: 1;
    width: 100%;
    margin: 10px 0;
  }

  .otp-btn {
    background-color: $accent-color;
    color: #fff;
    padding: 8px 0 6px 0;
    margin: 30px 0 10px 0;

    &:hover {
      background-color: $hover-color;
    }
  }
  .forgot-back-btn{
    border-radius: 8px;
  }
}
