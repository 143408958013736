.contract-travel-approved-shift-component {
  width: 100%;
  //padding: 20px;

  .form-holder {
    .MuiOutlinedInput-input {
      padding: 14px;
    }

    .form-field {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      text-align: left;
    }

    .form-label {
      margin: 5px 0;
      font-size: 16px;
      font-weight: 400;
    }

    .MuiFormHelperText-root.Mui-error {
      font-size: 13px;
      color: red;
      margin: 3px 0;
      text-align: right;
      position: absolute;
      top: -24px;
      right: 0;
    }

    .form-error {
      font-size: 13px;
      color: red;
      margin: 3px 0;
      text-align: right;
    }
  }
}
