.hcp-notes-component{
  .notes-heading{
    font-size: 18px;
    color: black;
    font-weight: 600;
  }
  .notes-panel{
    margin-top: 20px;
    color: #2C2C2C;
    width: 100%;
    padding: 10px;
    .notes-author{
      margin-top: 10px;
      font-size: 10px;
      color: #908f8f;
      font-weight: 400;
    }
    .notes-box{
      width: 100%;
    }
  }
  .MuiInputBase-inputMultiline {
    border: none !important;
  }

}