@import "src/assets/scss/common";

.facility-filters {
  .form-field-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-field-left {
      width: 100%;
        flex: 2;
      .form-field-left-items {
        display: flex;
        gap: 5px;
        width: 100%;

        .form-field-item {
          flex: 1;
        }
      }
    }

    .form-field-right {
      margin-top: 0.6rem;
      width: 20%;
    }
  }

  .facility-chips-wrapper {
    display: flex;
    align-items: baseline;

    .hcp-chips {
      gap: 5px;

      .MuiChip-root {
        background: #e3fff4;
      }

      .MuiChip-label {
        color: $accent-color;
      }

      .MuiChip-deleteIcon {
        color: $accent-color;
      }
    }
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }
  .react-datepicker-popper {
    z-index: 999 !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-bottom: 1rem;
  }

  .react-datepicker__day-name {
    color: #0c80e3;
    font-size: 14px;
    font-weight: bold;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range .react-datepicker__header {
    background-color: #0c80e3 !important;
  }

  .custom-input {
    cursor: pointer;
    font-size: 16px;
    padding: 0.25rem 0.8rem;
    width: 100%;
    height: 2.6rem;
    border: 0.5px solid lightgray;
    border-radius: 4px;
  }

  .date-icon {
    float: right;
    margin-right: 6px;
    margin-top: -2.1rem;
    position: relative;
    z-index: 5;
  }

  .custom-input:focus {
    outline: none;
  }

  .clear-all-filters {
    cursor: pointer;
    margin-left: 0.5rem;
    font-size: small;
  }
  .show-filter {
    display: inline-block;
  }
  .hide-filter {
    display: none;
  }
}
