.email-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  background-color: #FAFAFA;

  .email-content-wrapper {
    max-width: 1200px;
    width: 100%;
    flex-grow: 1;
    margin: 80px auto 40px auto;
  }

  .email-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    .email-save-button {
      margin-right: 50px;
      background-color: #3ad4c5;
      border: none;
      color: #ffffff;
      height: 35px;
      font-size: 1rem;

      &:hover {
        background-color: #3ad4c5;
        color: #ffffff;
      }
    }
  }
}
