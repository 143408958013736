@import "src/assets/scss/common";

.employee-edit-component {
  position: relative;
  .employee-edit {
    padding-top: 80px;

    .employee-edit-content{
      max-width: 1150px;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }


    .card-header {
      color: $light-black;
      font-size: 18px;
      font-weight: 500 !important;
    }

    .input-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 1.5rem;
      margin: 2rem 0;

      .flex-1 {
        flex: 1px;
      }

      .input-field {
        margin-top: 10px;
        flex: 0.7;
        display: flex;
        gap: 0.5rem;
      }
    }

    .MuiTypography-body1 {
      color: $text-light;
    }

    .MuiIconButton-label {
      color: $text-light;
    }

    .eye_btn_wrapper {
      position: absolute;
      top: 7px;
      right: 10px;
      z-index: 2;
    }
  }
}
