//.date-picker-container {
//  display: flex;
//  flex-direction: column;
//  gap: 0.5rem;
//  margin-bottom: 1rem;
//
//  .label {
//    font-size: 1rem;
//    font-weight: 500;
//    color: var(--inputLabel);
//  }
//
//  .date-picker {
//    width: 100%;
//
//    .ant-picker-input > input {
//      font-size: 1rem;
//      padding: 0.25rem;
//    }
//
//    .ant-picker {
//      border-radius: 0.3rem;
//      border-color: var(--primary);
//
//      &:hover {
//        border-color: var(--primaryHover);
//      }
//    }
//  }
//
//  .error-message {
//    font-size: 0.875rem;
//    color: var(--error);
//    margin-top: 0.25rem;
//  }
//}

.custom-date-picker {
  width: 100% !important;
}
