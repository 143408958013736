@import "src/assets/scss/common";

.facility_view_details {
	//width: calc(100vw - 80px) !important;

	.basic-details{
		width: 100%;
	}

	@include md() {
		//width: calc(100vw - 280px) !important;
	}
	.fac-edit-btn {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 1rem;
	}

	.title-rating-container {
		display: flex;
		justify-content: center;
	}

	.rating-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-top: 10px;
		.rating-title {
			color: $text-light;
			font-size: 18px;
		}

		.rating-content {
			margin-left: 5px;
			color: $primary-color;
			font-size: 22px;
		}
	}

	//padding: 20px;
	.custom-border {
		padding: 16px !important;
		border-radius: 0.5rem;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}
	.title-count {
		color: $light-black;
		margin-bottom: 0px;
		margin-top: 30px;
	}
	h2 {
		color: rgb(250, 221, 221);
	}
	.location {
		margin-top: -21px;
	}
	.location_icon {
		font-size: 1rem !important;
		color: $light-black;
	}
	.mrg-top-3 {
		margin-top: 7px;
	}
	.d-flex {
		display: flex;
		width: 100%;
		gap: 20px;
	}
	.flex-1 {
		flex: 1px;
		max-width: 32% !important;
	}
	.location_name {
		margin-top: -1px;
		margin-left: 5px;
		color: $text-light;
	}
	button {
		text-align: center !important;
		margin: auto;
	}
	h4 {
		color: $text-light;
		font-weight: 400;
	}
	p {
		color: $light-black;
		margin-top: -10px;
	}
	h3 {
		color: $light-black;
	}
	.edit-facility-wrapper {
		justify-content: space-between;
		h2 {
			color: $primary-color;
			flex: 1px;
			font-weight: 400;
		}
		div {
			margin: auto;
			margin-right: 10px;
		}
	}
	.MuiPaper-elevation1 {
		box-shadow: none !important;
	}
	.MuiAccordionSummary-root {
		padding: 0px !important;
		padding-top: 10px !important;
	}
	// .custom-border{
	// 	width: 78vw;
	// }
	.MuiAccordionSummary-expandIcon{
		border-radius: 5px;
		margin-top: -10px;
		padding: 8px;
	}
	.MuiAccordionDetails-root {
		padding: 0px !important;
	}
	.MuiAccordionSummary-content {
		width: 100%;
		p {
			font-size: large;
			font-weight: 600;
			color: $light-black;
		}
	}
}

.max-width {
	max-width: fit-content;
}

.other-details-wrapper{
	width: 100%;
}