@import "src/assets/scss/common";

.add-group{
    .MuiFormControl-fullWidth {
        width: 250px;
    }
    .padding{
        padding:20px 0px 20px 15px !important;
    }
    .group-title{
        width: 100%;
    }
    .filter{
        h2{
            margin-top: 0px;
            color:$light-black;
        }
        p{
            margin-top: -15px;
            color:$light-black;
        }
    }
    .button-wrapper{
        text-align: center;
        margin-top: 30px;
       .add-button{
           margin: auto;
       }
    }
    .message-wrapper{
        border: 1px solid black;
        border-radius: 10px;
        justify-content: center;
        width: 150px;

    }
    .add-members-list-table{
        .mat-td-checkbox{
            min-width: 30px;
            max-width: 30px;
        }
        .mat-td-hcp-type{
            min-width: 150px;
            max-width: 150px;
          }
      
          .mat-td-hcp-name{
            min-width: 250px;
            max-width: 250px;
          }      
          .mat-td-actions{
			z-index: 99;
            min-width: 120px;
            max-width: 120px;
            text-align: center !important;
          }
    }
}