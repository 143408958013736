.hcp-sent-home-dialog-component{
    .hcp-sent-home-details-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    
    
        
    }
    
    .hcp-sent-home-dialog-heading{
        text-align: center;
    }
}