@import "src/assets/scss/common";

.dashboard {
  width: 100%;
  height: calc(100vh - 64px);

  .map-container {
    width: 100%;
    height: 100%;
  }
}

.map-component {
  background: white;
  position: relative;

  .top-filter-section-holder {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 1;
    width: 700px;
  }

  .top-card-details-section-holder {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 1;
  }

  .bottom-tools-section-holder {
    z-index: 1;
    position: absolute;
    display: flex;
    bottom: 30px;
    justify-content: center;
    align-items: flex-end;

    &.on-left {
      left: 5px;
    }

    &.on-right {
      right: 30px;
    }
    &.on-right-center{
      right: 80px;
    }
    &.on-center-padding{
      right: 450px;
    }
    &.on-center{
      right: 400px;
    }
  }

  .control-base {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .map-pointer-legend-control {
    color: #161F41;
    background: white;
    font-weight: 400;
    padding: 10px 0;
    font-size: 16px;
    width: 320px;

    .map-pointer-legend-control-label {
      min-width: 60px;
    }

    .map-pointer-legend-control-value {
      min-width: 220px;
    }
  }

  .zoom-trigger-control {
    width: 36px;
    padding: 10px 6px;
    //height: 62px;
    margin-left: 30px;
    //border: 1px solid #2D3657;
    // background: linear-gradient(180deg, $zoom-gradient-start 0%, $zoom-gradient-end 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;

    .zoom-control {
      color: #FFFFFF;

      .zoom-control-icon {
        color: $text-on-accent-color;
        opacity: 0.8;
      }

      &:hover {
        .zoom-control-icon {
          color: $text-on-accent-color;
          opacity: 1;
        }

        cursor: pointer;
        opacity: 1;
      }

      &.zoom-control-plus {
        position: relative;
        top: 2px;
      }
    }

    .zoom-level {
      margin: 5px 0;
      color: #161F41;
      font-size: 14px;
      font-weight: bold;
      font-family: Roboto, sans-serif;
    }
  }

  .location-target-mode-active {
    .ol-unselectable {
      z-index: 3 !important;
      background-color: white;
    }
  }
  .legend-card-opener{
    min-width: 142px;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 5px 15px;
    min-height: 39px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: #121F3E;
  }
}


@media print {
  .map-component {
    visibility: visible;
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: calc(100vw - 10px);
    height: calc(100vh - 10px);
  }
}
