.facility-add-container {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid #ffffff;
    text-align: left;
  }

  .facility-add-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;

    .number-container {
      display: flex;
      width: 100%;
      gap: 10px;

      .phone-number {
        width: 75%;
      }

      .extension-number {
        width: 25%;
      }
    }

    .add-member-icon {
      cursor: pointer;
    }
  }

  .facility-add-btn-grp {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .facility-add-action {
    margin: 3rem 0;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
