@import "src/assets/scss/common";

.ac-list{
  width: calc(100vw - 265px);
  .employee-table-component{
    .ant-table-body{
      max-height: 58vh !important;
      min-height: 58vh !important;
    }
}
  .custom-border{
    padding: 20px !important;
  }
    .Active{
      color: $primary-color !important;
    }
    .Inactive{
      color: $text-light !important;
    }
    .employee-list-table{
        .mat-td-employee-name{
          min-width: 250px;
          max-width: 250px;
        }
    
        .mat-td-contact-number{
          min-width: 180px;
          max-width: 180px;
        }
    
        .mat-td-email{
          min-width: 250px;
          max-width: 250px;
        }
        .mat-td-role{
          min-width: 150px;
          max-width: 150px;
        }
        .mat-td-status{
          min-width: 100px;
          max-width: 100px;
        }
    
        .mat-td-actions{
			    z-index: 99;
          min-width: 120px;
          max-width: 120px;
        }
      }
  .ellipses-for-table-data {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}