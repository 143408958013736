.facility-details-card-component {
  padding: 17px 14px;
  width: 350px;
  background-color: #FFFFFF;
  border-radius: 5px;
  .header-text {
    font-weight: 700;
    font-size: 14px;
    color: #1F1F1F;
  }
  .header-sub-text{
    font-weight: 400;
    font-size: 14px;
    color: #1F1F1F;
  }


  .facility-details-header {
    display: flex;
    justify-content: space-between;


    .header-icon {
      cursor: pointer;
    }
  }


  .facility-status-wrapper {
    display: flex;
    justify-content: right;
    align-items: center;

    .active-icon {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 5px;
    }

    .active {
      background: #00A962;
    }

    .inactive {
      background: #9c9e9d;
    }
  }

  .status-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #2C2C2C
  }

  .facility-name-wrapper {
    display: flex;
    background: #F8F9FC;
    border-radius: 10px;
    padding: 12px;
    margin-top: 10px;
    align-items: center;

    .facility-name-text-symbol {
     min-width: 4rem;
      min-height: 4rem;
      background: #447299;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      color: #FFFFFF;
      margin-right: 8px;
    }

    .facility-name-text {
      font-weight: 700;
      font-size: 14px;
      color: #4E4E4E;
      max-height: 4rem;
      overflow: hidden;
      word-break: break-all;
    }
  }
  .basic-details-wrapper{
    display: flex;
    padding: 10px;
    .details-header-text-content{
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #4E4E4E;
    }
  }
  .details-header-text{
    font-weight: 400;
    font-size: 10px;
    color: #4E4E4E;
    opacity: 0.5;
  }
  .open-shift-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #F8F9FC;
    border-radius: 5px;
    cursor: pointer;
  }

}




$primary-color: #10c4d3;
$accent-color: #0c80e3;


.capitalize {
  text-transform: capitalize;
}



.loading-text-wrapper {
  text-align: center;
  span {
    display: inline-block;
    color: gray;
  }
}
.form-field-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.form-field-wrapper>* {
  flex: 1 1 10px;
}

.custom-chips-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  .custom-chips {
    gap: 5px;

    .MuiChip-root {
      background: #e3fff4;
    }

    .MuiChip-label {
      color: $accent-color;
      // text-transform: capitalize;
    }

    .MuiChip-deleteIcon {
      color: $accent-color;
    }
  }
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}
.react-datepicker-popper {
  z-index: 999 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-bottom: 1rem;
}

.react-datepicker__day-name {
  color: #0c80e3;
  font-size: 14px;
  font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range .react-datepicker__header {
  background-color: #0c80e3 !important;
}

.custom-input {
  cursor: pointer;
  font-size: 16px;
  padding: 0.25rem 0.8rem;
  width: 100%;
  height: 2.4rem;
  border: 0.5px solid lightgray;
  border-radius: 4px;
  z-index: 9999;
}

.date-icon {
  float: right;
  margin-right: 6px;
  margin-top: -2.1rem;
  position: relative;
  z-index: 999;
}

.custom-input:focus {
  outline: none;
}

.clear-all-filters {
  cursor: pointer;
  margin-left: 0.5rem;
  font-size: small;
}
.show-filter {
  display: inline-block;
}
.hide-filter {
  display: none;
}

