
.ant-select-selector:hover {
  border-color: #40D6C3 !important; /* Ensure it persists on focus */
  // box-shadow: 0 0 0 2px rgba(64, 214, 195, 0.2) !important; /* Optional focus shadow */
}

.ant-select-selector:focus {
  border-color: #40D6C3 !important; /* Ensure it persists on focus */
  box-shadow: 0 0 0 2px rgba(64, 214, 195, 0.2) !important; /* Optional focus shadow */
}

.ant-select-open .ant-select-selector {
  border-color: #40D6C3 !important; /* Persist when dropdown is open */
  box-shadow: 0 0 0 2px rgba(64, 214, 195, 0.2) !important;
}
