.shift-card-component {
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  .shift-header-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .shift-title {
    font-weight: 700;
    font-size: 14px;
    color: #1F1F1F;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .shift-card-text {
    font-weight: 400;
    font-size: 10px;
    color: #4E4E4E;
    opacity: 0.5;
  }

  .shift-card-value {
    font-weight: 700;
    font-size: 12px;
    color: #4E4E4E;
  }
}