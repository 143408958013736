@import "src/assets/scss/common";

.hcp-list {
  width: calc(100vw - 265px);
  .custom-border{
    padding: 20px;
  }
  .approved-staff-table-wrapper{
    .ant-table-body{
      min-height: 58vh !important;
      max-height: 58vh !important;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 2px !important;
  }


 
  .hcp-list-table{


    .mat-td-created-at{
      min-width: 200px;
      max-width: 300px;
    }

    .mat-td-hcp-name{
      min-width: 250px;
      max-width: 250px;
    }

    .mat-td-email{
      min-width: 150px;
      max-width: 150px;
    }

    .mat-td-contact-number{
      min-width: 200px;
      max-width: 200px;
    }

    .mat-td-hcp_uniqueId{
      min-width: 150px;
      max-width: 150px;
    }

    .mat-td-hcp-type{
      min-width: 100px;
      max-width: 100px;
    }

    .mat-td-status{
      min-width: 100px;
      max-width: 100px;
    }

    .mat-td-actions{
			z-index: 99;
      min-width: 120px;
      max-width: 120px;
    }
  }
}
