//@import "src/assets/scss/common";
//
//.timeline{
//   margin-top: 5rem;
//   position: relative;
//
//   .gap-container{
//    display: flex;
//    gap: 5rem;
//  }
//
//    .wrap {
//        width: 98%;
//        margin:auto;
//        text-align: center;
//        position: relative;
//      }
//      .points {
//        display: flex;
//        justify-content: space-between;
//        position: relative;
//      }
//      .wrap:before {
//        content: '';
//        position: absolute;
//        top: 50%;
//        left: 0;
//        border: 0.25px dotted lightgray;
//        border-style: dashed;
//        width: 100%;
//        transform: translateY(-50%);
//      }
//
//
//
//      .dot {
//        width: 30px;
//        height: 30px;
//        border-radius: 50%;
//        background:$primary-color;
//        position: relative;
//
//        .dot-title {
//            position: absolute;
//            top: -2rem;
//            right: -1.5rem;
//            width: 5rem;
//            font-size: 14px;
//            font-weight: 400;
//            color: $primary-color;
//        }
//
//
//
//
//        .dot-time {
//            position: absolute;
//            top: 3rem;
//            right: -1.5rem;
//            width: 5rem;
//            font-size: 13px;
//            color: $accent-color;
//            font-weight: 400;
//        }
//      }
//
//
//
//      .small-dot {
//        width: 15px;
//        height: 15px;
//        border-radius: 50%;
//        background:$primary-color;
//        position: relative;
//        margin-top: 7px;
//
//        .small-dot-title {
//            position: absolute;
//            top: -2rem;
//            right: -1.5rem;
//            width: 5rem;
//            font-size: 14px;
//            font-weight: 400;
//            color: $primary-color;
//        }
//
//
//
//
//        .small-dot-time {
//            position: absolute;
//            top: 3rem;
//            right: -2rem;
//            width: 5.2rem;
//            font-size: 14px;
//            color: $accent-color;
//            font-weight: 400;
//        }
//      }
//
//      .timeline-hours {
//        max-width: 100%;
//        height: 2rem;
//        text-align: center;
//        color: gray;
//        font-size: 18px;
//        margin-top: -14px;
//    }
//
//
//}

.shift-timeline {
  .ant-card {
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem;
  }

  .timeline-title {
    text-align: center;
    margin-bottom: 2rem;
  }

  .timeline-container {
    display: flex;
    align-items: center;
    gap: 1.3rem;
    position: relative;
    overflow-x: auto;
    padding: 1rem 0;
  }

  .timeline-event {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-shrink: 0;

    .icon {
      font-size: 2rem;
      color: #16C6CF;
    }

    .event-content {
      background: #f9f9f9;
      padding: 1rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-top: 0.5rem;

      .event-title {
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
      }

      .event-time {
        font-size: 1.2rem;
        color: #16C6CF;
        font-weight: bold;
      }

      .event-date {
        font-size: 0.9rem;
        color: #8c8c8c;
      }

      .location {
        font-size: 0.8rem;
        color: #0c0b0b;
        font-weight: bold;
      }

      .outside-location{
        font-size: 0.8rem;
        color: red;
        font-weight: bold;
      }
    }
  }

  .timeline-connector {
    width: 2rem;
    height: 2px;
    background: #d9d9d9;
    flex-shrink: 0;
  }
}

