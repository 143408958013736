.add-member-group{
    .padding{
        padding:20px 0px 20px 15px !important;
    }
    .add-members-group-list-table{
        .mat-td-checkbox{
            min-width: 30px;
            max-width: 30px;
        }
        .mat-td-hcp-type{
            min-width: 150px;
            max-width: 150px;
          }
      
          .mat-td-hcp-name{
            min-width: 250px;
            max-width: 250px;
          }      
          .mat-td-actions{
			z-index: 99;
            min-width: 120px;
            max-width: 120px;
          }
    }
    .button-wrapper{
        text-align: center;
        margin-top: 30px;
       .add-button{
           margin: auto;
       }
    }
}