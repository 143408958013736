@import "src/assets/scss/common";

.hcp-list {
  width: calc(100vw - 265px);

  .custom-border{
    padding: 20px !important;
  }

  .hcp-table-component{
    .ant-table-body{
      min-height: 58vh !important;
      max-height: 58vh !important;
    }
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 2px !important;
  }
  .table-row-wrapper {
    overflow: scroll !important;
    height: 300px !important;
   }
   .table-row-wrapper{
       overflow: scroll !important;
       height: 300px !important;
   }
  .text-right {
    text-align: right !important;
    padding-right: 20px !important;
  }
  .last-row {
    border-radius: 0px 5px 5px 0px;
  }
  .first-row {
    border-radius: 5px 0px 0px 5px;
  }
  .hcp-list-table{

    .mat-td-created-at{
      min-width: 200px;
      max-width: 300px;
    }

    .mat-td-hcp-name{
      min-width: 250px;
      max-width: 250px;
    }

    .mat-td-email{
      min-width: 150px;
      max-width: 150px;
    }

    .mat-td-contact-number{
      min-width: 200px;
      max-width: 200px;
    }

    .mat-td-hcp_uniqueId{
      min-width: 150px;
      max-width: 150px;
    }

    .mat-td-status{
      min-width: 100px;
      max-width: 100px;
    }

    .mat-td-actions{
			z-index: 99;
      min-width: 120px;
      max-width: 120px;
    }
  }
}
