.edit-job-form-container-whole {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .form-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 9px 24px;
  }

  .form-body {
    flex: 1;
    overflow: auto;
    border-radius: 12px;
    border: 1px solid var(--gray-300, #D4D4D8);
    background: var(--white, #FFF);
    margin: 30px 144px 20px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;

  }

  .form-footer {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #ffffff; // Optional, for contrast with content
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow for separation

    .exit-button {
      border: 1px solid #d9d9d9;
      background-color: #ffffff;
      color: #595959;
      margin-right: 1rem;
      height: 40px;
      font-size: 1rem;

      &:hover {
        background-color: #f5f5f5;
        color: #262626;
      }
    }

    .next-button {
      background-color: #3ad4c5;
      border: none;
      color: #ffffff;
      height: 40px;
      font-size: 1rem;
      margin-right: 70px;

      &:hover {
        background-color: #32b9ab !important;
      }
    }
  }

}

.job-text-editor{
  border: 1px solid var(--gray-300, #D4D4D8);
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  .ql-container{
    height: 180px;
    overflow-y:scroll;
  }
}
