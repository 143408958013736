
.bulk-facility-filter {
  .MuiInputBase-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}


.bulk-upload-dialog {
  .MuiTypography-body1 {
    color: #0DB9D8 !important;
  }


  .MuiFormControlLabel-root.Mui-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
