@import "src/assets/scss/common";

.login-screen {
  margin-top: 30px;

  .main-auth-wrapper {
    @include sm() {
      padding: 20px 40px;
    }
  }
  .input-cursor {
    caret-color: $primary-color;
  }
  .MuiIconButton-sizeSmall{
    font-size: 1rem !important;
  }
  .login-button {
    margin: auto;
    color: white;
    background-color: $accent-color;
    &:hover {
      background-color: $hover-color;
    }
  }
  .auth-header {
    margin: 0 0 0;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
  }
  .auth-subheader{
    text-align: center;
    font-size: 16px;
    color: $text-light;
  }
  .form-label{
    color: $text-light;
    font-size: 14px;
  }

  .loginFormHolder {

    .eye_btn_wrapper {
      position: absolute;
      top: 35px;
      right: 10px;
      z-index: 2;
    }
  }

  .forgot-password-holder {
    text-align: right;
    margin-bottom: 20px;
    margin-top: 10px;

    .forgot-password-wrapper {
      font-size: 12px;
      color:$accent-color;
      outline: none;
      font-weight: 400;
      cursor: pointer;
      opacity: 0.9;

      text-decoration: none;

      &:hover {
        color:$hover-color;
      }
    }
  }
}
