@import "../../../../assets/scss/common";

.generate-invoice-component {
  color: $vitawerks-font-color;
  .generated-invoice-table-wrapper{
      .ant-table-body{
        min-height: 45vh !important;
        max-height: 45vh !important;
      }
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 2px !important;
  }

  .filter-wrapper {
    display: flex;
    .search-box {
      margin-top: 10px;
      min-height: 40px;

      .searchFieldGeneratedInvoice {
        width: 450px !important;
      }
    }

    .status-filter-box {
    }

    .date-filter-box {
    }
  }
}
