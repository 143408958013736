@import "src/assets/scss/common";

.add-shift-component{
  position: relative;
.add-shifts {
  max-width: 1150px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  border-radius: 0.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .MuiTypography-body1 {
    color: $text-light !important;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .shift-rate-container {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .shift-text-container {
      .shift-text {
        font-size: 20px;
        font-weight: 500;
      }

      .shift-sub-text {
        color: $text-light;
        text-align: left;
        font-size: medium;
      }
    }

    .shift-rate-actions {
      gap: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .shift-readonly-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }

  .shift-readonly-row-item {
    flex: 1;
  }

  .add-shift-requirment-text {
    color: $primary-color;
    text-decoration: underline;
    width: 200px;
    margin: auto;
    font-weight: 500;
    cursor: pointer;
  }

  .add-shift-requirment-text-light {
    color: $text-light;
    cursor: default;
  }


  .shift-calender {
    flex: 1;
    display: flex;
    width: 100%;
    position: relative;

    .date-icon {
      position: absolute;
      right: 10px;
      top: 2.8rem;
    }

    .rmdp-container {
      width: 100%;
    }

    .MuiSvgIcon-fontSizeLarge {
      font-size: 25px;
    }

    .custom-input {
      cursor: pointer;
      font-size: 16px;
      padding: 0.25rem 0.5rem;
      width: 100%;
      height: 3rem;
      width: 100%;
      border: 0.5px solid lightgray;
      border-radius: 4px;
    }

    .custom-input:focus {
      outline: none;
    }
  }

  .shift-mode {
    flex: 1;
  }

  .shift-date-range-wrapper{
    display: flex;
    margin-top: 30px;
    width: 100%;
  }

  .shift-header-container {
    display: flex;
    justify-content: flex-start;

    .shift-header {
      color: #000;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .shift-add-action {
    margin-top: 1rem;
    text-align: center;
  }

  .shift-header {
    text-align: right;

    .close-icon {
      fill: red;
    }

    .add-icon {
      fill: green;
    }
  }

  .custom-card {
    padding: 1rem 2rem 2rem 2rem;
    border-radius: 0.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .facility-rate-container {
    position: relative;
    display: flex;
    justify-content: space-between;

    .facility-rate-value {
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;

      p {
        font-weight: 600;
      }
    }

    .facility-rate-value-active {
      color: $backdrop-color;
    }

    .facility-rate-value-inactive {
      color: $text-light;
    }

    .facility-rate-info {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .shift-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .shift-item {
      margin-top: 1rem;
      flex: 1;
    }
  }

  .shift-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .add-shift-btn-grp {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  label[data-shrink="false"] {
    color: gray !important;
  }

  .MuiInputBase-inputMultiline {
    border: none !important;
  }

  .rmdp-panel-body li,
  .rmdp-panel-body li.bg-blue {
    background-color: $accent-color;
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: $accent-color;
  }
}

.file-holder-wrapper {
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  background: rgba(17, 197, 209, 0.10);;
  padding: 5px;
  min-width: 300px;
  color: #1e1e1e;
}


// CSS Custom Property
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}


.multiple-date-box {
  cursor: pointer;
  font-size: 16px;
  padding: 1.7rem 0.8rem;
  width: 100%;
  height: 2.4rem;
  border: 0.5px solid lightgray;
  border-radius: 6px;
}

.shift-open-container {
  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }

  .input-container-minor {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 1.5rem;
    margin-bottom: 1rem;
    width: 33.99%;
  }
}


.input-container-minor-two {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 65.8%;
}

.width-minor {
  width: 65.8%;
}

}