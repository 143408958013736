@import "src/assets/scss/variables";

.file-preview-component{
  display: flex;
  align-items: center;
  gap: 16px;

  .file-preview-image-wrapper{
    position: relative;
    width:80px;
    height: 80px;

    .file-preview-image{
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: contain;
      border: 1px solid $light-gray;

    }

  }

  .file-preview-btn-wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
  }
}