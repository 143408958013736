@import "mixins";

$font-family: 'Inter';
$primary-color: #10c4d3;
$text-on-primary-color: #FFF;
$accent-color: #0c80e3;
$text-on-accent-color: #FFF;
$background-color: #ffffff;
$blue-color: #3ca7f4;
$text-light:#808080;
$backdrop-color: #41D6C3;
$light-black:#1e1e1e;
$light-gray:#D3D3D3;
$vitawerks-font-color:#121F3E;
$black:#000000E0;
$light-gray-second:#52525B;
$chip-color:#C8FCFE;