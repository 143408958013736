@import "src/assets/scss/common";

.hcp-assigned-nc-list {
	.nc-assigned-hcps-list-table {
		.mat-td-hcp-name {
			min-width: 250px;
			max-width: 250px;
		}
		.mat-td-contact-number {
			min-width: 150px;
			max-width: 150px;
		}
		.mat-td-role {
			min-width: 100px;
			max-width: 100px;
		}
		.mat-td-monthly-status {
			min-width: 100px;
			max-width: 100px;
		}
		.mat-td-actions {
			min-width: 120px;
			max-width: 120px;
		}
	}
	.Active{
		color: $primary-color !important;
	}
	.Inactive{
		color:$text-light !important;
	}
	.view-details{
		color:$light-black !important;
	}
}
