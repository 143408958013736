@import "src/assets/scss/common";

@media print {

}

.generated-invoice-view-details-component {
  max-width: calc(100vw - 80px) !important;
  color: #6D6D6D;

  @include md() {
    max-width: calc(100vw - 270px) !important;
  }

  .interior-border {
    border: 1px solid #E1E1E1
  }

  .billing-form {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .billing-details {
    display: flex;
    flex-direction: column;
  }

  .detail {
    margin: 5px 0;
  }

  .label {

  }

  .data-grid {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;

      .col {
        flex: 1;
        //background-color: #f2f2f2;
        padding: 10px;
        border: .5px solid #ccc;
        overflow-x: auto;
        white-space: nowrap;
      }
      .col-width{
       // min-width: 150px;
      }
    }
  }
  .data-grid .row .col::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

}

.pdf-download-component{
  color: black;
  font-size: 9px;
.header-download{
  display: flex;
  justify-content: space-between;
}
  .summary{
   font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-left: 20%;
  }
  .billing-to{
    border: 1px solid #c1bebe;
    padding: 10px;
  }
  .data-grid-download {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;

      .col {
        flex: 1;
        //background-color: #f2f2f2;
        padding:4px;
        border: .5px solid #c1bebe;
        overflow-x: auto;
        white-space: nowrap;
      }
      .bold{
        font-weight: 600;
      }
      .col-width{
        // min-width: 150px;
      }
    }
  }
  .data-grid-download .row .col::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }
  .billing-form-download {
    display: flex;
    justify-content: space-between;
    border: 1px solid #c1bebe;
    padding:10px;
  }
}