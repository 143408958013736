.job-component-list-screen {
  width: calc(100vw - 265px);

  .job-table-container {
    border: 0.5px solid #F0F0F0;
  }
  .add-job-link {
    text-decoration: none; // Remove underline from the link
  }

  .add-job-button {
    background-color: #41D6C3;
    border: none;
    border-radius: 6px;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    padding: 0 20px 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #32b9ab !important;
    }

    .anticon {
      font-size: 16px;
      margin-right: 2px;
    }
  }

  .add-job-outline-button {
    background: #ffffff;
    border: 1px solid #41d6c3 !important;
    border-radius: 6px;
    color: #41d6c3;
    font-size: 14px;
    font-weight: 400;
    padding: 0 20px 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;

    &:hover {
      border: 1px solid #41d6c3 !important;
      color: #41d6c3 !important;
      background: #ffffff;
    }
  }
}
