.view-job-container-whole {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .ellipses-for-email{
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .view-job-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 9px 24px;
  }

  .job-view-details-content {
    flex: 1;
    max-width: 1200px;
    margin: 0 auto;
  }

  .header-back-content {
    cursor: pointer;
  }

  .header-back {
    color: black;
    font-size: 16px;
  }

  .job-details-content-container {
    border-radius: 12px;
    border: 1px solid var(--gray-300, #D4D4D8);
    background: var(--white, #FFF);
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    overflow: auto;
    max-height: 80vh;
    width: 1200px;
    margin: 0 auto;

    .add-job-button {
      background-color: #41D6C3;
      border: none;
      border-radius: 6px;
      color: #FFF;
      font-size: 14px;
      font-weight: 400;
      padding: 0 20px 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #32b9ab !important;
        color: #FFFFFF;
      }

      .anticon {
        font-size: 16px;
        margin-right: 2px;
      }
    }
  }
}

.view-details-document-tag {
  display: flex;
  flex-wrap: wrap; // Allows items to wrap to the next line
  gap: 0.5rem;

  .document-tag {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    border-radius: var(--Components-Tag-Global-borderRadiusSM, 4px);
    border: var(--Components-Tag-Global-lineWidth, 1px) solid var(--Colors-Base-Blue-3, #40D6C3);
    background: var(--Colors-Base-Blue-1, #d1faf5);
    color: #073d36;
  }

  .job-doc-text {
    font-size: 12px;
  }
}
