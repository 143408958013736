.cancelled-shifts {
	width: calc(100vw - 265px);

	.custom-border{
		padding: 20px !important;
	}
	.cancelled-table-wrapper{
		margin-top: 10px;
		.ant-table-body{
			min-height: 56vh !important;
			max-height: 56vh !important;
		}

	}
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 2px !important;
  }

  .danger {
    color: #f10000 !important;
  }
  .success {
    color: #00da16 !important;
  }
  
  .shifts-cancelled-list-table {
		.mat-td-title {
			min-width: 250px;
			max-width: 250px;
		}
    .mat-td-shift-date{
      min-width: 120px;
			max-width: 120px;
    }
    
		.mat-td-facility-name {
			min-width: 250px;
			max-width: 250px;
		}

		.mat-td-hcp-name {
			min-width: 250px;
			max-width: 250px;
		}
    .mat-td-hcp-type {
			min-width: 100px;
			max-width: 100px;
		}
    .mat-td-shift-type {
			min-width: 80px;
			max-width: 80px;
		}
		.mat-td-differential {
			min-width: 150px;
			max-width: 150px;
		}
		.mat-td-is-facility-approved{
			min-width: 80px;
			max-width: 80px;
		}
		.mat-td-actions {
			z-index: 99;
			min-width: 120px;
			max-width: 120px;
		}
	}
}
