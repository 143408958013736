@import "src/assets/scss/variables";
.email-document-component{
  position: relative;
  .email-document-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color:$background-color;
    border-bottom: 1px solid var(--gray-300, #D4D4D8);
    display: flex;
    align-items: center;
    justify-content: center;
    .vitawerks-logo{
      width: 160px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .email-document-content{
    padding-top: 100px;
    max-width: 1200px;
    margin: 0 auto;

    .email-document-holder{
      height: calc(100vh - 200px);
      border: 1px solid var(--gray-300, #D4D4D8);
      border-radius: 12px;
      padding: 2rem 1rem;

      .no-document-text{
        height: calc(100vh - 200px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 26px;
        color: $primary-color;
      }

      .document-loader{
        width: 100%;
        height: calc(100vh - 240px);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .required-documents-title{
        font-weight: 600;
        font-size: 16px;
      }
      .document-list{
        margin-top: 10px;
        height: calc(100vh - 240px);
        overflow-y: scroll;

        .upload-btn{
          background-color: #3ad4c5;
          border: none;
          color: #ffffff;
          font-size: 1rem;
          padding: 10px 20px;

          &:hover {
            background-color: #32b9ab !important;
            color: #FFFFFF !important;
          }
        }

        .submission-title{
          font-weight: 500;
          margin-top: 20px;
        }

        .document-card{
          margin:10px 0;
          border-radius: 12px;
          padding:10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}
