@import "src/assets/scss/common";

.shift-completed-view {
	.facility-details {
		justify-content: space-between;
		padding: 10px 20px;
		.button {
			text-align: end;
		}
	}
	.file-actions{
        color: $primary-color !important;
        margin-top: 0px;
        text-decoration: underline;
        cursor: pointer;
    }
	.delete-cdhp {
		color: $primary-color;
		text-decoration: underline;
		margin-left: 10px;
		width: 90px;
	}
	.MuiFormControl-fullWidth {
		background-color: #f6fefb;
	}
	.flex-2 {
		flex: 2;
	}
	.hcp-name-type {
		margin-left: 20px;
		justify-content: center;
		h2 {
			margin-bottom: 0px;
		}
		p {
			margin-top: 0px;
		}
	}
	.hcp-details {
		flex-wrap: wrap;
		h4 {
			color: $text-light;
		}
		p {
			margin-bottom: 0px;
			color: $light-black;
		}
	}
	.hcp-photo-details-wrapper {
		margin: auto;
		justify-content: space-between;
		padding-bottom: 15px !important;
		.ratings {
			text-align: center;
			h4 {
				color: $text-light;
				margin-bottom: 0px;
			}
			p {
				color: $primary-color;
				font-size: 20px;
				margin-top: 0px;
			}
		}
	}
	.shift-name-requested {
		justify-content: space-between;
		.requested-on-wrapper {
			text-align: center;
			p {
				margin-top: 18px;
			}
		}
	}
	.feedback-rating-wrapper {
		h3 {
			color: $text-light;
			font-weight: 500;
		}
	}
	.shift-ot-time {
		justify-content: end;
	}
	.hcp-rate {
		text-align: end;
		color: $light-black;
		margin-bottom: 0px;
		span {
			color: $text-light;
			font-weight: 500;
		}
	}
	h2 {
		color: $light-black;
	}
	p {
		color: $text-light;
	}
	h3 {
		color: $light-black;
	}
	.flex-1 {
		flex: 1px;
	}

	.flex-baseline {
		align-items: baseline;
	}

	.flex-container{
		justify-content: space-between;
	}

	.flex-center{
		align-items: center;
		
	}
	.shift-details {
		h3 {
			margin-bottom: 0px !important;
			color: $text-light;
			font-weight: 410;
			font-size: 15px;
		}
		p {
			font-size: 14px;
			color: $light-black;
			margin-top: 5px !important;
		}
	}
	.actions-wrapper {
		margin: auto;
	}
	.status-wrapper {
		margin: auto;
		margin-right: 5px;
		.status-details {
			font-size: large;
			text-transform: capitalize;
		}
	}
	.justifyContent {
		justify-content: space-between;
	}
	.file-icon {
		font-size: 6rem;
		color: #41d5c2;
	}
	.MuiInputBase-inputMultiline{
		border: none !important;
	}
    .MuiOutlinedInput-multiline{
        padding: 0;
    }

	.shift-view-actions{
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;

	}
	.attended-date {
		font-size: 16px;
		margin-top: 17px !important;
	}

	.attachments{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
}
