.job-basic-details-form {

  .shift-row{
    display: flex;
    gap: 20px;
    width: 100%;

    .time-wrapper{
      width: 100% !important;
    }
  }

  .shift-date-range-wrapper{
    display: flex;
    margin-top: 30px;
    width: 100%;
    .shift-mode {
      flex: 1;
    }

    .shift-calender {
      flex: 1;
      display: flex;
      width: 100%;
      position: relative;

      .date-icon {
        position: absolute;
        right: 10px;
        top: 2.5rem;
      }

      .rmdp-container {
        width: 100%;
      }

      .MuiSvgIcon-fontSizeLarge {
        font-size: 25px;
      }

      .custom-input {
        cursor: pointer;
        font-size: 16px;
        padding: 0.25rem 0.5rem;
        width: 100%;
        height: 2rem;
        border: 0.5px solid lightgray;
        border-radius: 4px;
      }

      .custom-input:focus {
        outline: none;
      }
    }
  }

  .job-text-editor{
    border: 1px solid var(--gray-300, #D4D4D8);
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
    .ql-container{
      height: 180px;
      overflow-y:scroll;
    }
  }
}




.custom-radio .ant-radio-checked .ant-radio-inner {
  background-color: #2ACFCF;
}
