.pay-rate-component{
  height:calc(100vh - 320px) !important;
  overflow-y:scroll;
  scroll-behavior: smooth;
  .ant-form-item{
      margin-bottom: 0 !important;
  }

  .pay-rate-table-wrapper{
    .ant-table-body{
      min-height: unset !important;
      max-height: unset !important;
    }
  }
}
