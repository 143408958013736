@import "../../../assets/scss/common";

.app-layout {
  .brand {
    width: calc(240px - 10px);
    align-items: center;
    display: flex;
    color: black;
    justify-content: space-between;

    img {
      width: 160px;
    }
  }

  .view-loading-indicator {
    height: calc(100vh - 64px);
    width: 100%;

    .loader {
      position: relative;
      top: 50%;
      left: 50%;
      z-index: 100;
    }
  }

  .makeStyles-appBar-2 {
  }

  .MuiAppBar-root {
    box-shadow: 0 0 2px #aaaaaa !important;
  }

  .MuiPaper-root.MuiDrawer-paper,
  .MuiDrawer-docked {
    transition: width .05s linear;
    box-shadow: 0 0 4px #AAAAAA70;
  }

  .drawerOpened .MuiPaper-root.MuiDrawer-paper,
  .drawerOpened.MuiDrawer-docked {
  }

  .drawerClosed,
  .drawerOpened {
    .MuiListItem-button {
      width: 98%;

      .inactive-icon {
        display: none;
        height: 20px;
        color: $text-light;
      }

      &.active {
        background: #f6fefb;

        .active-icon {
          display: none;
          height: 20px;
          margin-top: -10px;
        }

        .inactive-icon {
          display: inline;
          height: 20px;
          margin-top: 0px;
        }

        .MuiListItemIcon-root {
          color: $backdrop-color;
          // color: grey;
        }

        .MuiTypography-body1 {
          color: $backdrop-color;
        }

        .MuiListItemText-root {
          font-weight: bold;
        }
      }
    }

    .MuiTypography-body1 {
      color: rgb(153, 144, 144);
    }

    .MuiDrawer-paper {
      .MuiListItemIcon-root {
        //min-width: 52px;
        //color: $accent-color;
        color: grey;
        justify-content: flex-start;
        margin: 4px 0 4px 8px;
      }
    }
  }

  .drawerOpened {
    width: 240px;

    .MuiPaper-root.MuiDrawer-paper,
    &.MuiDrawer-docked {
      width: 265px;
    }

    .MuiDrawer-paper {
      .MuiListItemIcon-root {
        min-width: 40px;
      }

      .MuiListItemText-root {
        display: block;
      }
    }
  }

  .MuiListSubheader-sticky {
    top: 0;
    z-index: 1;
    position: inherit;
    background-color: inherit;
    color: $light-black;
  }

  .drawerClosed {
    width: 0px;

    .powered-by-holder {
      display: none;
    }

    .MuiPaper-root.MuiDrawer-paper,
    &.MuiDrawer-docked {
      width: 0px;
    }

    .MuiListItem-gutters {
      padding-left: 10px;
      padding-right: 10px;
    }

    .MuiDrawer-paper {
      .MuiListItemIcon-root {
        justify-content: center;
        min-width: 34px;
      }

      .MuiListItemText-root {
        display: none;
      }
    }
  }

  .powered-by-holder {
    flex: 1;
    display: flex;
    position: absolute;
    bottom: 10px;
    width: 100%;
    flex-direction: column;

    .logout-wrapper {
      margin: 20px 0;
      //text-align: center;
      justify-content: center;
      display: flex;
      flex: 1;

      button {
        width: 70%;
        text-transform: capitalize;
      }

      .logout-custom-btn {
        background-color: #ffffff;
        border: 1px solid #caced1;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 60%;
        padding: 6px 20px;
        color: #475f7b;
        outline: none;
        cursor: pointer;
        opacity: 0.9;

        &:hover {
          opacity: 1;
          box-shadow: 0 1px 6px #AAAAAA60;
        }
      }
    }

    .powered-by-wrapper {
      display: flex;
      flex-direction: column;
      margin: 10px;
      font-size: 12px;
      text-align: center;
      line-height: 24px;
      color: $accent-color;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .logout-holder {
    color: $accent-color;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.9;

    &:hover {
      opacity: 1;
      color: red;
    }
  }

  .page-container {
    flex: 1;
    display: flex;
    //height: calc(100vh - 64px);
    overflow: auto;
    //	background-color: #EDFDF7 ;

  }

  .menuButton {
    @include md() {
      display: none;
    }
  }

  .back-title-holder {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-left: 20px;
    flex: 1;

    button {
      margin-right: 20px;
    }

    .page-title-text {
      font-size: 1rem;
      padding-left: 20px;
      @include sm() {
        font-size: 16px;
      }
      @include md() {
        font-size: 18px;
      }
      font-weight: 400;
      color: $light-black;
    }
  }

  .notifications {
    margin-right: 2%;
    cursor: pointer;

    .MuiBadge-colorPrimary {
      color: #ffffff !important;
    }
  }

  .custom-border {
    padding:10px 20px;
    border-radius: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
  }

  .filter-icon {
    cursor: pointer;
  }

  .searchField {
    width: 280px !important;
  }

  .styles_scroll-to-top__2A70v {
    background-color: transparent;
    background-image: linear-gradient(to right, #10c4d3, #4fe6af);
  }

  .account_name {
    color: $text-light;
    font-size: medium;
  }

  .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
}
