.ant-table {
  // margin: 1rem 0;

  .ant-input-number {
    width: 100%;
  }

  .ant-select {
    width: 100%;
  }

  .ant-checkbox-wrapper {
    justify-content: center;
  }
}


.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #41D6C3 !important;
    border-color: #41D6C3 !important;
  }
}


.rate-card-form {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
