@import "src/assets/scss/common";

.facility-list {
  width: calc(100vw - 265px);

  .custom-border{
    padding: 20px !important;
    border-radius: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
  }

  .facility-table-component{
    .ant-table-body{
      min-height: 56vh !important;
      max-height: 56vh !important;
    }
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 2px !important;
  }
  .text-right {
    text-align: right !important;
    padding-right: 20px !important;
  }
  .last-row {
    border-radius: 0px 5px 5px 0px;
  }
  .first-row {
    border-radius: 5px 0px 0px 5px;
  }

  .facility-list-table{

    .mat-td-created-at{
      min-width: 100px;
      max-width: 100px;
    }

    .mat-td-facility-name{
      min-width: 250px;
      max-width: 250px;
    }

    .mat-td-region-name{
      min-width: 150px;
      max-width: 150px;
    }

    .mat-td-phone-number{
      min-width: 200px;
      max-width: 200px;
    }

    .mat-td-is-active{
      min-width: 100px;
      max-width: 100px;
    }

    .mat-td-actions{
			z-index: 99;
      min-width: 120px;
      max-width: 120px;
    }
  }
}

.ellipses-for-table-data {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.facility-filter-btn{
  background: #1e1e1e;
}