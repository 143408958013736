@import "src/assets/scss/common";

.employee_view_details{
    width: calc(100vw - 80px) !important;

	@include md() {
		width: calc(100vw - 280px) !important;
	}
    .mi-tab{
        max-width: 200px !important;
    }
    .profile-actions{
        margin: auto;
        margin-right: 0px !important;
        justify-content: end;
    }
    h3{
        color: $light-black;
        text-transform: capitalize;
    }
    .flex-1 {
		flex: 1px !important;
		max-width: 48% !important;
	}
    h4 {
		color: $text-light;
        font-weight: 400;
	}
    p {
		color: $light-black;
		margin-top: -10px;
		overflow-wrap: break-word;
		word-wrap: break-word;
		hyphens: auto;
		text-align: left;
	}
    .ac-profile{
        width: 100%;
        justify-content: space-between;
    }
    .role{
        color: $text-light !important;
    }
    .status{
        color: $primary-color !important;
        font-weight: 400;
        font-size: 1.5rem;
        margin-top: 15px;
    }
  .ellipsis-data {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}