.legend-card-component{
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  padding: 5px 15px;
  align-items: center;
  color: #000000;
  .selected-legend{
    background: #dcd3cf;
    border-radius: 26px;
    display: flex;
    padding:3px 5px;
  }
  //.legend-details-holder{
  //  display: flex;
  //  margin-bottom: 10px;
  //  .legend-details{
  //    display: flex;
  //    width: 50%;
  //    .legend-details-icon{
  //       margin-right: 10px;
  //      margin-left: 10px;
  //    }
  //    .legend-details-text{
  //      font-weight: 400;
  //      font-size: 12px;
  //      color: #646464;
  //    }
  //  }
  //}
  //.horizontal-line-separator{
  //  width: 215px;
  //  border: 1px solid #D9D9D9;
  //}
  //.inactive-status-legend{
  //  display: flex;
  //  .legend-details{
  //    display: flex;
  //    margin-top: 10px;
  //    .legend-details-icon{
  //      margin-left: 10px;
  //    }
  //    .legend-details-text{
  //      font-weight: 400;
  //      font-size: 12px;
  //      color: #646464;
  //    }
  //  }
  //}
}