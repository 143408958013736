.applicant-component{

  .applicant-btn-wrapper{
    display: flex;
    align-items: center;
    gap: 25px;
    .applicant-box{
      border: 1px solid lightgray;
      display: flex;
      align-content: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      cursor: pointer;

      .applicant-edit-icon{
        height: 28px;
        width: 22px;
      }
    }
  }
}