@import "responsive_mixins";
// responsive mixins for global use
@import "mixins"; // Color mixins for global use

$primary-color: #10c4d3;
$text-on-primary-color: #FFF;
$accent-color: #0c80e3;
$text-on-accent-color: #FFF;
$background-color: #ffffff;
$blue-color: #3ca7f4;
$text-light:#808080;
$backdrop-color: #41D6C3;
$light-black:#1e1e1e;
$vitawerks-font-color:#121F3E;
$border-color:#D2D2D2;
$hover-color:#2C69BC;
