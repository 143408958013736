@import "src/assets/scss/common";

.add-hcp-requirment {
  .change-staff-wrapper{
    max-height: 350px;
    overflow-y: scroll;
  }
  .MuiIconButton-label {
    color: $primary-color !important;
  }
  .MuiTypography-body1 {
    color: $light-black;
  }
  .MuiOutlinedInput-inputMarginDense {
    width: 520px !important;
  }
}
