@import "src/assets/scss/variables";

.facility-filter-component{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;

  .facility-filter-header-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .facility-filter-header{
      display: flex;
      align-items: center;

      .facility-filter-title{
        font-weight: 600;
        font-size: 18px;
        margin-right: 20px;
      }
      .facility-filter-clear-all{
        color:$primary-color;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .facility-filter-close-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer;
    }
  }
  .facility-filter-fields-wrapper{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;

    .facility-date-range{
      position: relative;

        .facility-custom-input {
          cursor: pointer;
          font-size: 14px;
          padding: 0.5rem 0.8rem;
          width: 100%;
          height: 3.5rem;
          border: 1px solid #C4c4c4;
          border-radius: 4px;
          z-index: 9999;
          display: flex;
          align-items: center;
          font-family: $font-family;
        }

        .facility-date-icon{
          position: absolute;
          top: 12px;
          right: 8px;
        }
    }
    .hcp-chips-wrapper {
      display: flex;
      align-items: baseline;
      gap: 10px;

      .hcp-chips {
        gap: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;


        .MuiChip-root {
          background: #e3fff4;
        }

        .MuiChip-label {
          color: $accent-color;
        }

        .MuiChip-deleteIcon {
          color: $accent-color;
        }
      }
    }
  }
}