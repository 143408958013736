@import "src/assets/scss/common";


.show-shift-rate-screen {
    .MuiTypography-body1 {
        color: #000  !important;
    }

    .main-heading {
        font-weight: bold;
        font-size: 24px;
    }

    .sub-heading{
        font-weight: 500;
        font-size: 16px;
    }

    .show-shift-rate-container{
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .shift-rate-radio-container{
            display: flex;
            flex-direction: row;
        }
    }


    .shift-rate-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        padding: 0;
        margin-top: 10px;

        .shift-rate-input-wrapper{
            max-width: 130px;
        }
    }
}