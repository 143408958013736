@import "src/assets/scss/common";

.pending-shifts-takeover{
    .title{
        font-weight: 450;
        margin-bottom: 0px;
    }
    .request{
        color: $primary-color !important;
        margin-top: 5px;
    }
    .or-text{
        margin:20px auto;
        text-align: center;
        font-weight: 450;
    }
}