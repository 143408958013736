.shift-rate-edit-approved-container {
  min-width: 100% !important;
  //border: 1px solid #e8e8e8;
  //border-radius: 8px;
  //padding: 0.7rem;

}

.card {

}

.custom-antd-input-number input {
  /* Remove spinner controls for modern browsers */
  -moz-appearance: textfield;
  width: 80px !important;
}

.custom-antd-input-number input::-webkit-inner-spin-button,
.custom-antd-input-number input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Ensure no layout or hover effects cause the spinner to reappear */
.ant-input-number {
  position: relative;

  .ant-input-number-handler-wrap {
    display: none; /* Hide Antd spinner handlers */
  }
}

.custom-antd-input-number {
  input[disabled] {
    color: black !important;
  //  font-weight: 400/* Dark black text */
   // font-weight: bold !important; /* Bold text */
  }
}

.disabled-color {
  background-color: #F1F8F8 !important;
  border-radius: 5px;
}

.shift-header-holiday{
  font-size: 16px;
  font-weight: 700;
  color: black;
}


.custom-radio {
  // Increase the size

  .ant-radio {
    width: 1.2rem;
    height: 1.2rem;

    .ant-radio-inner {
      width: 1.2rem;
      height: 1.2rem;

      // Border and hover color
      border-width: 2px;
    }

    &:hover {
      .ant-radio-inner {
        border-color: #11C5D1; // Hover border color
      }
    }

    &-checked {
      .ant-radio-inner {
        border-color: #11C5D1; // Change border color when checked
        background-color:  #11C5D1; // Change background color when checked
      }
    }
  }
}



.custom-checkbox {

  .ant-checkbox {
    width: 1.2rem;
    height: 1.2rem;

    .ant-checkbox-inner {
      width: 1.2rem;
      height: 1.2rem;
      border-width: 2px;
     // transition: all 0.3s ease;
    }

    // Change border color on hover
    &:hover {
      .ant-checkbox-inner {
        border-color: #11C5D1; // Hover border color
      }
    }

    &-checked {
      .ant-checkbox-inner {
        border-color: #11C5D1; // Checked border color
        background-color: #11C5D1; // Checked background color
      }

      // Ensure the checkmark inside is white or stands out
      .ant-checkbox-inner::after {
        border-color: white;
      }
    }
  }
}
