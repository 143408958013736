

.filter-component-for-map {
  .form-field-wrapper {
    display: flex;
    .form-field-left {
      .form-field-left-items {
        display: flex;
        gap: 10px;
        .form-field-item {
          flex: 1;
        }
      }
    }
  }
}
