@import "src/assets/scss/common";

.open-shifts-list {
	width: calc(100vw - 265px);
	.open-table-wrapper{
		.ant-table-body{
			min-height: 55vh !important;
			max-height: 55vh !important;
		}
	}
	.open-header{
		display: flex;
		flex-direction: column;
		gap: 10px;

		.open-header-section{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
		.open-more-btn{
			display: flex;
			justify-content: flex-end;
			margin-bottom: 10px;
		}
	}
	.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
		padding: 2px !important;
	}
	.MuiPaper-elevation1 {
		box-shadow: none !important;
	}
	.filter-icon {
		font-size: 2rem;
		color: $light-black;
	}
	
	.cancelled {
		color: #f10000 !important;
	}
	.closed {
		color: #00da16 !important;
	}
	.pending {
		color: #454545 !important;
	}
	.complete {
		color: #0c80e3 !important;
	}
	.in_progress {
		color: #ffa800 !important;
	}
	.danger {
		color: #f10000 !important;
	  }
	  .success {
		color: #00da16 !important;
	  }

	.open-shifts-list-table {
		.mat-td-facility-name {
			min-width: 250px;
			max-width: 250px;
		}

		.mat-td-title {
			min-width: 250px;
			max-width: 250px;
		}

		.mat-td-shift-date {
			min-width: 120px;
			max-width: 120px;
		}

    .mat-td-hcp-count{
      min-width: 80px;
			max-width: 80px;
    }

    .mat-td-shift-timings{
      min-width: 150px;
			max-width: 150px;
    }

    .mat-td-status{
      min-width: 100px;
			max-width: 100px;
    }
    .mat-td-shift-type{
      min-width: 80px;
			max-width: 80px;
    }

		.mat-td-hcp-type {
			min-width: 100px;
			max-width: 100px;
		}

		.mat-td-actions {
			z-index: 99;
			min-width: 120px;
			max-width: 120px;
		}
	}
}
