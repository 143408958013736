@import "src/assets/scss/common";

.edit-facility-component{
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
.facility-main {
  //padding: 0.5rem 3rem 3rem 3rem;
  padding-top: 50px;
  max-width: 1150px;
  margin: 0 auto;

  .MuiTypography-body1 {
    color: $text-light !important;
  }

  .card-header {
    color: #000;
    font-size: 18px;
    font-weight: 500;
  }

  .facility_image_actions{
    p{
      color:$light-black;
      text-decoration: underline;
      margin-top: 5px;
      margin-left: 5px;
    }
  }

  .header {
    color: $primary-color;
    font-size: 22px;
  }

  .phone-number {
    width: 75%;
  }

  .extension-number {
    width: 25%;
    margin-left: 0.5rem;

    .MuiOutlinedInput-input {
      padding: 13px !important;
    }
  }


  .close-icon {
    fill: red;
  }

  .add-icon {
    fill: green;
  }

  .edit-icon {
    fill: orange;
  }

  .delete-icon {
    fill: red;
  }

  .custom-border {
    padding: 1rem 3rem 2rem 2rem;
    border-radius: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .generic-add-multiple{
		color: $primary-color;
		text-decoration: underline;
		margin: auto;
		font-weight: 500;
		cursor: pointer;
		font-size: 18px;
    
	  }

  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 1.5rem;
    margin: 2rem 0;
  }

  .MuiTypography-root .MuiTypography-h5 {
    color:$light-black;
  }

  .attachments_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .attachments {
      flex: 50%;
      margin-top: 20px;
    }
  }

  .facility-about {
    margin: 2rem 0;
  }

  .facility-documents {
    margin: 2rem 0;
  }

  .facility-special-consideration {
    margin: 2rem 0;
  }

  .facility-actions {
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }


  label[data-shrink='false'] {
    color: gray !important;
  }
  .facility-image-header{
    color:#41d6c3 !important;
    font-weight: 420 !important;
    font-size: medium !important;
  }
}
}