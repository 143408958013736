@import "src/assets/scss/common";

.pending-shifts {
	width: calc(100vw - 265px);

	.approved-table-wrapper{
		margin-top: 10px;
		.ant-table-body{
			min-height: 56vh !important;
			max-height: 56vh !important;
		}
	}

	.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
		padding: 2px !important;
	  }
	.MuiPaper-elevation1 {
		box-shadow: none !important;
	}
	.filter-icon {
		font-size: 2rem;
		color: $light-black;
	}
	.shifts-approved-list-table{
		.mat-td-title {
			min-width: 250px;
			max-width: 250px;
		}
		.mat-td-created-at{
			min-width: 250px;
			max-width: 250px;
		}
		.mat-td-hcp-name {
			min-width: 250px;
			max-width: 250px;
		}

		.mat-td-facility-name {
			min-width: 250px;
			max-width: 250px;
		}

		.mat-td-shift-date {
			min-width: 120px;
			max-width: 120px;
		}
		
		.mat-td-shift-type {
			min-width: 80px;
			max-width: 80px;
		}

		.mat-td-is-facility-approved{
			min-width: 80px;
			max-width: 80px;
		}

		.mat-td-is-timesheet{
			min-width: 80px;
			max-width: 80px;
		}

		.mat-td-hcp-type {
			min-width: 100px;
			max-width: 100px;
		}

		.mat-td-actions {
			z-index: 99;
			min-width: 120px;
			max-width: 120px;
		}
	}
}
