@import "src/assets/scss/common";

.shift-cancelled-view{
    .MuiInputBase-inputMultiline{
        border: none !important;
    }

    .shift-details h3 {
    margin-bottom: 0px !important;
    color: #808080;
    font-weight: 410;
    font-size: 15px;
}
    .facility-details{
        justify-content: space-between;
        padding: 10px 20px;
        .button{
           text-align: end;
        }
    }

    .shift-name-action-container{
        display: flex;
        justify-content: baseline;
        align-items: baseline;
        gap: 1rem;
    
        h4{
            text-decoration: underline;
            letter-spacing: 0.1rem;
            cursor: pointer;
        }
    }
    
    .actions-wrapper{
        margin: auto;
    }
    .MuiFormControl-fullWidth {
        background-color: #F6FEFB;
    }
    .MuiOutlinedInput-multiline{
        padding: 0;
    }
    .flex-2{
        flex:2;
    }
    .hcp-name-type{
        margin-left: 20px;
        justify-content: center;
        h2{
            margin-bottom: 0px;
        }
        p{
            margin-top: 0px
        }
    }
    .hcp-details{  
        flex-wrap: wrap;

        h4{
            color:$text-light ;
        }
        p{
            margin-bottom: 0px;
            color:$light-black;
        }
    }
    .hcp-photo-details-wrapper{
        margin: auto;
        justify-content: space-between;
        padding-bottom: 15px !important;
        .ratings{
            text-align: center;
            h4{
                color: $text-light;
                margin-bottom: 0px;
            }
            p{
                color: $primary-color;
                font-size: 20px;
                margin-top: 0px;
            }
        }
    }
    .attended-date{
        font-size: 16px;
        margin-top: 15px;
    }
    .shift-name-requested{
        justify-content: space-between;
        .requested-on-wrapper{
         text-align: center;
         p{
             margin-top: 18px;
         }
        }
    }
    .feedback-rating-wrapper{
        h3{
            color: $text-light;
            font-weight: 500;
        }
    }
    .shift-ot-time{
     justify-content: end;
    }
    .hcp-rate{
        margin: auto;
        margin-right: 5px;
        color:$light-black;
        margin-bottom: 0px;
        span{
            color: $text-light;
            font-weight: 500;
        }
    }
    h2{
        color:$light-black;
    }
    p{
        color:$text-light;
    }
    h3{
        color:$light-black;
    }
    .flex-1{
        flex:1px;
    }
    .shift-details{
        p{
            font-size: 14px;
            color:$light-black;
        }
    }
    .reject-by-wrapper{
        h3{
            color: $primary-color !important;
        }
    }

    .input-container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: 1.5rem;
        margin-bottom: 1rem;
      }
    
      .action-btn-grp {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
      }


      .fac-confirm-container {
        display: flex;
        align-items: center;
      }

      .attachments{
		display: flex;
		flex-direction: column;
		align-items: center;
	}


	.file-actions{
        color: $primary-color !important;
        margin-top: 0px;
        text-decoration: underline;
        cursor: pointer;
    }
	.delete-cdhp {
		color: $primary-color;
		text-decoration: underline;
		margin-left: 10px;
		width: 90px;
	}
	.MuiFormControl-fullWidth {
		background-color: #f6fefb;
	}
	.flex-2 {
		flex: 2;
	}

	.shift-name-requested {
		justify-content: space-between;
		.requested-on-wrapper {
			text-align: center;
			p {
				margin-top: 18px;
			}
		}
	}

	.file-icon {
		font-size: 6rem;
		color: #41d5c2;
	}
      
}