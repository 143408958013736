.data-label-value {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  .data-label {
    font-weight: 400;
    font-size: 1rem;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #949494;

  }

  .data-value {
    line-height: 17px;
    font-size: 1rem;
    align-items: center;
    color: #212B36;
    font-weight: 500;
    margin-top: 0px;
    word-break: break-word;
  }

  &.row {
    flex-direction: row;
    align-items: center;

    .data-label {
      margin-left: 6px;
    }

    &:not(:first-child) {
      .data-label {
        margin-left: 0;
      }
    }
  }

  &.column {
    flex-direction: column;

    .data-label {
      margin-bottom: 6px;
    }
  }

}
