@import "common";
// global include variables
@import "responsive_display";
// responsive display classes
@import "helpers";
// width, height, margin, padding helper classes
@import "variables";

body {
  background-color: $background-color;
  scrollbar-width: thin;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.summary {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  text-align: left;
  max-width: calc(75vw - 100px);
}

.MuiDialogActions-root {
  justify-content: center !important;
}

.main-profile-pic {
  width: 440px;
  height: 500px;
  border: none;
}

.no-repeat-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.MuiFormLabel-root {
  color: $light-black !important;
}

.MuiTypography-body2 {
  color: $light-black !important;
}

.MuiTypography-h5 {
  color: $light-black !important;
}

.text-align {
  text-align: center !important;
}

.search_icon {
  color: #c4c4c4;
  cursor: pointer;
  position: absolute;
  top: 8px;
  z-index: 2;
  right: 10px;
}

#alert-dialog-title {
  h2 {
    color: $light-black !important;
  }

  .MuiFormLabel-root {
    color: $primary-color !important;
    margin-left: 10px;
  }
}

.screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  padding: 14px;
}

.table-loading-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.info-link {
  color: $primary-color;
  width: fit-content;
  //text-decoration: none;
}

.component {
  width: 100%;
}

.form-holder {
  .MuiOutlinedInput-input {
    padding: 16px;
  }

  .form-field {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    text-align: left;
  }

  .form-label {
    margin: 5px 0;
    font-size: 16px;
    font-weight: 400;
  }

  .MuiFormHelperText-root.Mui-error {
    font-size: 13px;
    color: red;
    margin: 3px 0;
    text-align: right;
    position: absolute;
    top: -24px;
    right: 0;
  }

  .form-error {
    font-size: 13px;
    color: red;
    margin: 3px 0;
    text-align: right;
  }
}

.MuiButtonBase-root {
  &.MuiButton-text {
    border-radius: 20px;

    &.MuiButton-textSizeSmall {
      padding: 4px 10px;
      transition: none;
    }
  }

  &.MuiButton-outlined {
    //	border-radius: 20px;
    background-color: #f6fefb;

    &.MuiButton-outlined {
      .MuiButton-label {
        color: $accent-color;
      }
    }

    &.MuiButton-outlinedPrimary {
      .MuiButton-label {
        color: $primary-color;
      }
    }

    &.MuiButton-outlinedSecondary {
      .MuiButton-label {
        color: #297eff;
      }
    }
  }

  &.MuiButton-contained {
    border-radius: 5px;

    &.MuiButton-containedSecondary {
      box-shadow: 1px 1px 4px #aaaaaa50;
      color: $text-on-primary-color;
      background-color: $accent-color;
    }

    &.MuiButton-containedSizeSmall {
      padding: 4px 10px;
      transition: none;
    }

    &.MuiButton-containedPrimary {
      &:not(.normal) {
        background-color: transparent;
        background-image: linear-gradient(to right, #10c4d3, #4fe6af);
      }

      color: #ffffff;
      box-shadow: 1px 1px 4px #aaaaaa50;
    }

    &.Mui-disabled {
      //background-image: none !important;
      //color: rgba(0, 0, 0, 0.26) !important;
      //box-shadow: none !important;
      //background-color: rgba(0, 0, 0, 0.12) !important;
      opacity: 0.6 !important;
      cursor: default !important;
    }
  }
}

.MuiDialogContent-root {
  &:first-child {
    padding-top: 0;
    padding-bottom: 10px;
  }

  padding: 0px !important;
}

.leave-page-confirmation-component {
  overflow: hidden;
}

.alert-dialog-slide-title {
  width: 100% !important;
  min-width: 360px !important;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .alert-dialog-heading {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    color: $light-black;
    font-weight: 400;
    font-size: 16px;
  }

  .text1 {
    font-weight: 400 !important;
  }

  .hcpname {
    font-weight: 600;
  }
}

.MuiDialog-paper {
  min-width: 340px;
  width: 100%;
}

.crud-layout {
  .header {
    margin-bottom: 10px;
    display: flex;

    .filter {
      flex: 1;
      display: flex;
    }

    .actions {
      button {
        margin-left: 10px;
      }
    }
  }
}

.dialog-main-holder {
  &.dialog-side-wrapper {
    .close-btn-holder {
      left: 10px;
      right: auto;
    }
  }

  &.close-to-right {
    .close-btn-holder {
      right: 10px;
      left: auto;
    }
  }

  .close-btn-holder {
    position: absolute;
    right: 10px;
    left: auto;
    top: 10px;
    z-index: 1;

    .close-btn-item {
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 10px #00859450;
      border-radius: 25px;
      background-color: $background-color;
    }
  }

  .preview-component {
    .MuiDialog-paperWidthSm {
      max-width: 700px !important;
    }
  }

  .pdf-wrapper {
    .MuiDialog-paperWidthSm {
      width: 1000px !important;
    }
  }
}

.dialog-side-wrapper {
  .MuiPaper-rounded {
    border-radius: 10px 0 0 10px !important;
    //box-shadow:$primary-color 0px 7px 109px 0px !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 420px;
    max-height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    position: absolute;
  }
}

.ts-file-drop-zone {
  padding: 20px;
  border: 2px dashed colorDarkness(#41d6c3, 10);
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: $accent-color;

  .file-upload-icon {
    margin: 10px 0;
    text-align: center;
    color: #41d6c3 !important;

    svg {
      width: 120px;
      height: 120px;
      fill: #41d6c3;
    }
  }

  .icon {
    color: #41d6c3 !important;
  }

  &.drag-enter-effect {
    border-color: $primary-color;
    background: $background-color;
    color: $primary-color;
  }
}

.dialog-content-wrapper {
  padding: 20px;

  .dialog-title-text {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    margin: 20px 0;
    color: $accent-color;
  }
}

.legend-line {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  display: table;
  font-weight: bold;
  font-size: 14px;
  font-style: italic;
  white-space: nowrap;

  &:before,
  &:after {
    border-top: 1px solid #aaaaaa30;
    content: "";
    display: table-cell;
    position: relative;
    top: 0.65em;
    width: 45%;
  }

  &:before {
    right: 1.5%;
  }

  &:after {
    left: 1.5%;
  }
}

.table-responsive {

  height: calc(100vh - 330px);
  min-height: 300px;
  width: calc(100vw - 80px) !important;

  @include md() {
    width: calc(100vw - 340px) !important;
  }

  .table {

    .mat-thead {
      width: 10px !important;

      .mat-tr {

        .mat-th {
          padding: 10px 8px 10px 15px;
          text-transform: uppercase;
          font-size: 13px;
          white-space: nowrap;
          color: $light-black;
          background-color: #e7fcf6;
        }

        .mat-th-sticky {
          //margin-left: 50px;
          //position: sticky;
          //right: 0;
          text-align: center;
        }
      }
    }

    .mat-tbody {
      .mat-tr {
        .mat-td {
          word-break: break-word;
        }

        .mat-td-sticky {
          //position: sticky;
          //right: 0;
          //background: white;
          //text-align: center;
        }
      }
    }
  }


  .MuiTableCell-stickyHeader {
    border: 0px !important;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      &.active {
        background-color: #e7fcf6;
      }

      .MuiTableCell-body {
        padding: 20px 8px 20px 15px;
        text-align: left;
        color: $light-black;

        .MuiIconButton-root.icon-btn {
          margin-right: 4px;
          padding: 6px;
        }
      }
    }
  }
}

.doc-table-responsive {
  height: auto;
  overflow-y: auto;
  max-height: 500px;
  width: calc(100vw - 80px) !important;
  @include md() {
    width: calc(100vw - 450px) !important;
  }

  .table {

    .mat-thead {
      width: 10px !important;

      .mat-tr {

        .mat-th {
          padding: 10px 8px 10px 15px;
          text-transform: uppercase;
          font-size: 13px;
          white-space: nowrap;
          color: $light-black;
          background-color: #e7fcf6;
        }

        .mat-th-sticky {
          //margin-left: 50px;
          //position: sticky;
          //right: 0;
          text-align: center;
        }
      }
    }

    .mat-tbody {
      .mat-tr {
        .mat-td {
          word-break: break-word;
        }

        .mat-td-sticky {
          //position: sticky;
          //right: 0;
          //background: white;
          //text-align: center;
        }
      }
    }
  }


  .MuiTableCell-stickyHeader {
    border: 0px !important;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      &.active {
        background-color: #e7fcf6;
      }

      .MuiTableCell-body {
        padding: 20px 8px 20px 15px;
        text-align: left;
        color: $light-black;

        .MuiIconButton-root.icon-btn {
          margin-right: 4px;
          padding: 6px;
        }
      }
    }
  }
}

.MuiTablePagination-root {
  margin: 10px 0;
}

.file-upload-actions {
}

.MuiInput-underline:after {
  border-bottom: 0px solid black !important;
}

.MuiMenuItem-root {
  color: $light-black !important;
}

.upload-progress-holder {
  margin: 30px 0;

  .upload-item {
    display: flex;
    margin: 10px 0;

    .upload-item-icon {
      height: 38px;
      margin-right: 10px;

      img {
        height: 100%;
      }
    }

    .upload-item-content {
      flex: 1;
      display: flex;
      position: relative;
      flex-direction: column;

      .upload-item-cancel {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.9;
        cursor: pointer;
        color: $primary-color;

        &:hover {
          opacity: 1;
          color: #ff2d55;
        }
      }

      .upload-item-content-name {
        font-size: 16px;
        color: $accent-color;
      }

      .upload-progress-bar {
        border-radius: 10px;
        background-color: #fceced;
        margin: 8px 0 0 0;
        overflow: hidden;

        .upload-progress-bar-fill {
          background-color: $primary-color;
          background-image: linear-gradient(to right, #df6737, #ff963b);
          width: 10%;
          height: 6px;
          border-radius: 10px;
        }
      }
    }
  }
}

.MuiCard-root {
  overflow: unset !important;
}

.modules-filter-component {
  &.btn-group {
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;

    button {
      margin: 0 20px;
      flex: 1;
    }
  }
}

.accordion.component {
  .accordionHeader {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

// inside model and responsive
.ts-grid {
  .ts-row {
    display: flex;
    flex: 1;
    flex-direction: column;
    @include sm() {
      flex-direction: row;
    }

    .ts-col {
      display: flex;
      flex: 1;
      margin-left: 0px;
      @include sm() {
        margin-left: 10px;
      }

      &:first-child {
        margin-left: 0;
      }

      .form-field {
        width: 100%;
      }
    }
  }
}

.flex-one {
  flex: 1 !important;
}

.flex-two {
  flex: 2 !important;
}

.color-success,
.color-pending,
.color-ignored {
  font-size: 14px;
  text-transform: capitalize;
}

.color-success {
  color: #07bc0c;
}

.color-pending {
  color: $accent-color;
}

.color-ignored {
  color: #bbb;
}

.custom_file {
  .file_name {
    text-transform: capitalize;
  }

  .file_image {
    max-width: 100px !important;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: -20px;

    .file_icon {
      height: 110px;
      width: 110px;
      color: $primary-color;
    }
  }

  .file_details {
    justify-content: center;

    .file_actions {
      .download-link {
        color: $primary-color;
        margin-top: 0px;
        border: 0px;
        background-color: white;
        padding: 0px;
        text-decoration: underline;
      }

      p {
        text-decoration: underline;
        color: $primary-color;
      }
    }
  }
}

.close-btn-holder {
  .MuiSvgIcon-colorPrimary {
    color: #000;
  }
}

.dialog-side-wrapper .MuiPaper-rounded {
  border-radius: 0;
}

.dialog-main-holder.dialog-side-wrapper .close-btn-holder {
  right: 10px;
  left: auto;
}

.dialog-main-holder .close-btn-holder .close-btn-item {
  box-shadow: none;
}

.custom-card {
  padding: 0 3rem 2rem 2rem;
  border-radius: 0.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tel-input-holder {
  .PhoneInputCountry {
    border: 1px solid #aaa;
    padding: 10px 6px;
    border-radius: 4px;
  }

  .PhoneInputInput {
    outline: none !important;
    border-radius: 4px;
    padding: 13px 14px;
    border: 1px solid #aaa;

    &:active,
    &:focus {
      padding: 12px 13px;
      border: 2px solid $primary-color;
    }

    font: inherit;
    color: currentColor;
    width: 100%;
    height: 1.189em;
    margin: 0;
    display: block;
    //padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
  }

  .PhoneInputCountryIcon--border {
    box-shadow: none !important;
    margin: 0px 3px;
  }
}

.MuiPickersBasePicker-pickerView {
  justify-content: center !important;
  max-width: 100% !important;
}

.MuiInputBase-inputMultiline {
  padding: 20px 15px !important;
  border: 2px solid $primary-color !important;
}

.MuiDialog-paperScrollPaper {
  border-radius: 20px !important;
}

.logout {
  color: #0c80e3 !important;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

.MuiOutlinedInput-root:hover fieldset {
  border-color: $primary-color;
}

.width-20 {
  width: 20px !important;
}

.has-loading-spinner {

  &::before {
    content: " ";
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    border: 3px solid $primary-color;
    border-top: 3px solid white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-animation: loaderSpin 0.5s linear infinite; /* Safari */
    animation: loaderSpin 0.5s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes loaderSpin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes loaderSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.align-items-center {
  align-items: center;
}

h6, h5, h4, h3, h2, h1, p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  text-align: left;
  max-width: 100%;
}


.btn-disabled {
  .MuiButton-label {
    color: lightgray !important;
  }
}


@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.map-loading:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid rgba($light-black, 0.4);
  border-top-color: rgba($light-black, 1);
  animation: spinner 0.6s linear infinite;
  z-index: 99;
}

.header-title {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.sub-header {
  font-size: 16px;
  font-weight: 400;
  margin-top: 50px;
  margin-bottom: 10px;
}


.dialog-header-wrapper {
  color: #1F1F1F;
  font-size: 14px;
  font-weight: 700;
}

.cross-icon-dialog {
  padding: 25px;
  cursor: pointer;
}


.dob-custom-input {
  cursor: pointer;
  font-size: 16px;
  padding: 0.9rem 0.8rem;
  width: 31rem;
  border: 0.5px solid lightgray;
  border-radius: 4px;
  z-index: 9999
}

.justify-content-center {
  justify-content: center;
}

.form-header-text {
  font-family: Public Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #1e1e1e;
}

.file-upload-supported-text {
  color: #889199;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.horizontal-line {
  width: 1053px;
  height: 1px;
  background: #E7E7E7;
}

.align-items-center {
  align-items: center;
}

.underline-text {
  text-decoration: underline;
}

.min-width-100 {
  min-width: 100%;
}

.reson-div {
  width: fit-content;
  margin-right: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiInputBase-input.Mui-disabled {
  background-color: #f1f8f8;
  cursor: not-allowed;
}


.bulk-upload-container-component {
  .table-responsive {

    height: calc(100vh - 330px);
    min-height: 300px;
    width: calc(100vw - 80px) !important;

    @include md() {
      width: calc(100vw - 410px) !important;
    }

    .table {

      .mat-thead {
        width: 10px !important;

        .mat-tr {

          .mat-th {
            padding: 10px 8px 10px 15px;
            text-transform: uppercase;
            font-size: 13px;
            white-space: nowrap;
            color: $light-black;
            background-color: #e7fcf6;
          }

          .mat-th-sticky {
            //margin-left: 50px;
            //position: sticky;
            //right: 0;
            text-align: center;
          }
        }
      }

      .mat-tbody {
        .mat-tr {
          .mat-td {
            word-break: break-word;
          }

          .mat-td-sticky {
            //position: sticky;
            //right: 0;
            //background: white;
            //text-align: center;
          }
        }
      }
    }


    .MuiTableCell-stickyHeader {
      border: 0px !important;
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &.active {
          background-color: #e7fcf6;
        }

        .MuiTableCell-body {
          padding: 20px 8px 20px 15px;
          text-align: left;
          color: $light-black;

          .MuiIconButton-root.icon-btn {
            margin-right: 4px;
            padding: 6px;
          }
        }
      }
    }
  }
}


.search-input-box {
  .MuiAutocomplete-input {
    padding: 4.5px 4px !important;
  }
}


.ant-switch.ant-switch-checked {
  background: #41D6C3 !important;
}


.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Three columns
  gap: 1rem;
}


.custom-checkbox-container {
  border: 1px solid #d9d9d9; // Light gray border
  border-radius: 4px; // Rounded edges
  padding: 0.7rem 1.2rem; // Padding for spacing
  display: flex; // Align elements horizontally
  align-items: center; // Center vertically
  // Pointer for interactivity
  width: 100%; // Box fits its content
  background-color: #fff; // White background
  transition: border-color 0.3s ease; // Smooth hover effect
  margin-bottom: 10px;

  &.checked {
    border-color: #36cfc9; // Highlight border color when checked
  }

  &:hover {
    border-color: #36cfc9; // Border color on hover
  }
}

.custom-checkbox {
  .ant-checkbox {
    cursor: pointer;

    &-inner {
      width: 20px; // Adjust checkbox size
      height: 20px;
      border-color: #36cfc9; // Custom border color
      border-width: 2px;

      &:after {
        border-color: #fff; // White checkmark color
        background-color: #36cfc9; // Inner teal fill for checked state
      }
    }

    &-checked .ant-checkbox-inner {
      background-color: #36cfc9; // Background color for checked state
      border-color: #36cfc9; // Border color for checked state
    }
  }
}

.checkbox-label {
  margin-left: 0.8rem; // Space between checkbox and text
  font-size: 1rem; // Label text size
  color: #000; // Text color
}


.box-disabled {
  background-color: #F1F8F8 !important;
  cursor: not-allowed !important;
}

.add-header-wrapper {
  background: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  z-index: 99;
  border-bottom: 1px solid $border-color;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 0 20px;

  .add-header-back-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .add-header-back {
      font-size: 16px;
      font-weight: 500;
      color: $light-black;
    }
  }

  .add-header-title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    justify-self: center;
    color: $light-black;
  }

  .add-header-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-right: 10px;
  }
}


.facility-add-btn-grp {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.add-fields-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: $primary-color;
  cursor: pointer;
  width: fit-content;
}

.optional-field-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.display-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-btn-wrapper {
  background: $light-black;
  //border: 1px solid $primary-color; /* Set the width of the border */
  //border-image-source: ;
  //border-image-slice: 1;
  color: #FFFFFF;
  padding: 7px 16px;
  overflow: visible;
  border-radius: 6px;
  width: 120px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}


.facility-edit-file {
  display: flex;
  align-items: center;
  gap: 10px;

  .facility-edit-image-wrapper {
    width: 80px;
    height: 80px;

    .facility-edit-image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: contain;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }
}

.custom-file-wrapper {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $light-gray;
  border-radius: 8px;

  .custom-file-icon {
    width: 50px;
    height: 50px;
  }
}

.text-lowercase {
  text-transform: lowercase;
}

.custom-date-input {
  cursor: pointer;
  font-size: 16px;
  padding: 20px 12px;
  width: 100%;
  border: 0.5px solid lightgray;
  border-radius: 4px;
}

.custom-date-icon {
  float: right;
  margin-right: 10px;
  margin-top: -2.8rem;
  position: relative;
  z-index: 999;
}

.custom-date-wrapper {
  cursor: pointer;
  font-size: 16px;
  padding: 10px 12px;
  width: 100%;
  border: 0.5px solid lightgray;
  border-radius: 4px;
}

.custom-date-icon-wrapper {
  float: right;
  margin-right: 10px;
  margin-top: -2.2rem;
  position: relative;
  z-index: 999;
}


.custom-modal .ant-btn-primary {
  background-color: #48E1B3; // Change the button background color
  border-color: #48E1B3;

  &:hover {
    background-color: #32d0a0 !important; // Change the button hover color
    border-color: #2db68d !important;
  }
}

.custom-modal .ant-btn-default {
  //background-color: #FF4D4F; // Customize default button color
  //border-color: #FF4D4F;
  //color: #FFFFFF;

  &:hover {
    background-color: #f6dddd !important; // Customize default button hover color
    border-color: #b61819 !important;
    color: black !important;
  }
}

//.custom-modal .ant-btn {
//  &:hover {
//    color: #fff; // Optional: Change text color on hover
//  }
//}


.file-holder-wrapper {
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  background: rgba(17, 197, 209, 0.10);;
  padding: 5px;
  min-width: 300px;
  color: #1e1e1e;
  width: 100%;
}

.flex-equal {
  flex: 1;
}

.flex-double {
  flex: 2;
}


.job-main-header {
  color: var(--gray-800, #27272A);
  font-size: 16px;
  //font-weight: bold;
  margin-bottom: 20px;
  font-weight: 600;
}

.header-title {
  color: #121F3E;
  font-size: 16px;
  font-weight: 600;
}


.document-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  border-radius: var(--Components-Tag-Global-borderRadiusSM, 4px);
  border: var(--Components-Tag-Global-lineWidth, 1px) solid var(--Colors-Base-Blue-3, #40D6C3);
  background: var(--Colors-Base-Blue-1, #d1faf5);
  color: #073d36;
}

.doc-cross-icon {
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.job-doc-text {
  font-size: 12px;
}

.add-job-link {
  text-decoration: none; // Remove underline from the link
}

.add-job-button {
  background-color: #41D6C3;
  border: none;
  border-radius: 6px;
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  padding: 0 20px 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #32b9ab !important;
    color: #FFFFFF;
  }

  .anticon {
    font-size: 16px;
    margin-right: 2px;
  }
}

.time-wrapper{
  width: 100% !important;
}

.applicant-box{
  border: 1px solid lightgray;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  cursor: pointer;

  .applicant-edit-icon{
    height: 28px;
    width: 22px;
  }
}


.text-header-for-soft-content {
  color: var(--gray-600, #A1A1AA);
  font-size: 14px;
  font-weight: 450;
  line-height: 150%;
}

.document-wrapper {
  border: 1px solid #F0F0F0;
  border-radius: 8px;
}

.color-underline-text {
  color: #3B82F6;
}

.table-under-select-box {
  .ant-form-item {
     margin-bottom: 0 !important;
  }
}
.rate-type-header{
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}