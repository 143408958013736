.hcp-no-show-component{
  .hcp-no-show-text{
    //margin-top: 50px;
    padding: 10px 20px;
    color: black;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}