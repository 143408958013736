@import "src/assets/scss/common";


.edit-timeline-container {
  padding: 1rem;

  .edit-timeline-header {
    font-size: 28px;
    text-transform: capitalize;
  }

  .edit-timeline-sub-header {
    color: $primary-color;
    font-size: large;
    margin-bottom: 1rem;
  }

  .edit-timeline-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .edit-timeline-title {
    font-size: 20px;
    font-weight: 600;
  }

  .edit-timeline-sub-title {
    font-size: 18px;
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }
}