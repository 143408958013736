@import "src/assets/scss/common";

.completed-shifts {
  width: calc(100vw - 265px);
  .custom-border{
    padding: 20px;
  }
  .completed-table-wrapper{
    margin-top: 10px;
    .ant-table-body{
      min-height: 56vh !important;
      max-height: 56vh !important;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 2px !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .danger {
    color: #f10000 !important;
  }
  .success {
    color: #00da16 !important;
  }
  
  .shifts-completed-list-table {
    .mat-td-title {
      min-width: 200px;
      max-width: 200px;
    }
    .mat-td-completed-date {
      min-width: 120px;
      max-width: 120px;
    }

    .mat-td-facility-name {
      min-width: 150px;
      max-width: 150px;
    }

    .mat-td-hcp-name {
      min-width: 150px;
      max-width: 150px;
    }
    .mat-td-hcp-type {
      min-width: 100px;
      max-width: 100px;
    }
    .mat-td-shift-type {
      min-width: 80px;
      max-width: 80px;
    }
    .mat-td-differential {
      min-width: 50px;
      max-width: 50px;
    }

    .mat-td-is-hcp-sent-home {
      min-width: 50px;
      max-width: 50px;
    }

    .mat-td-timesheet {
      min-width: 50px;
      max-width: 50px;
    }
    .mat-td-is-facility-approved {
      min-width: 100px;
      max-width: 100px;
    }

    .mat-td-close-btn{
      min-width: 50px;
      max-width: 50px;
    }
    .mat-td-actions {
			z-index: 99;
      min-width: 100px;
      max-width: 100px;
    }
  }
}
