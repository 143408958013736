.hcp-selection-component-for-message{
  padding: 30px 35px;
  width: 100% !important;

  .header-wrapper{
    font-weight: 700;
    font-size: 16px;
    color: #1F1F1F;
  }
  .region-name-text{
    font-weight: 500;
    font-size: 12px;
    color: #4E4E4E;
  }
  .hcp-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .hcp-list {
    .hcp-name-holder{
      display: flex;
      flex: 1 0 33.33%;
      align-items: center;
      .hcp-name{
        white-space: nowrap;
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .MuiInputBase-inputMultiline {
    border: none !important;
  }
}
.MuiSlider-root {
  .MuiSlider-markLabel {
    font-weight: 400;
    font-size: 10px;
    color: #000000;
    opacity: 0.46;
    font-style: italic;
  }
  .MuiSlider-rail{
    background: #11C5D1;
    opacity: 0.2;
    height: 8px;
   // border-radius: 5px;
  }
  .MuiSlider-track{
    background: #11C5D1;
    opacity: 1;
    height: 8px;
  //  border-radius: 5px;
  }
  .MuiSlider-mark{
    color:#11C5D1 ;
    width: 3px;
    height: 8px;
  }
  .MuiSlider-valueLabel{
    color: #b9b4b4;
  }
  .MuiSlider-markLabelActive {
    font-weight: 400;
    font-size: 10px;
    color: #000000;
    opacity: 1;
  }
  .MuiSlider-thumb{
    height: 15px;
  }
}