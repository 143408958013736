@import "src/assets/scss/common";

.notification-component {
    padding-left: 2rem;
  .notification-header {
    color: $primary-color;
    font-size: 28px;
    margin: 1rem 0;
  }

  .notification-preferences {
    padding-left: 1rem;
 

    .notification-group {
      color: $light-black;
      font-size: large;
      margin-bottom: 1rem;
    }

    .notification-preferences-container {
      display: flex;
      gap: 3.5rem;
      align-items: center;

      .form-control {
        .form-label {
          color: $text-light;
          font-size: medium;
        }
      }
    }
  }

  .custom-border {
    padding: 2rem 3rem 2rem 2rem;
  }
}
