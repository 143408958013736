/* src/components/CustomInput.scss */
.custom-input {
  margin-bottom: 1rem;

  .custom-input-label {
    font-weight: 500;
    margin-bottom: 0.2rem;
    display: block;
    color: var(--inputLabel);
  }

  .custom-input-error {
    color: var(--error);
    font-size: 0.7rem;
    margin-top: 0.4rem;
  }
}

//.formik-input-component {
.ant-input:hover {
  border-color: #40D6C3 !important; /* Ensure it persists on focus */
  // box-shadow: 0 0 0 2px rgba(64, 214, 195, 0.2) !important; /* Optional focus shadow */
}

.ant-input:focus {
  border-color: #40D6C3 !important; /* Ensure it persists on focus */
  box-shadow: 0 0 0 2px rgba(64, 214, 195, 0.2) !important; /* Optional focus shadow */
}

//}

