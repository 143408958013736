@import "src/assets/scss/common";

.create-shift {
  .hcp-pay-date-container {
    padding: 0.25rem 2rem 0.25rem 2rem;
    width: 36%;
  }
  .shift-heading-container {
    margin-left: 2rem;

    .shift-heading {
      margin-bottom: 5px;
      font-size: 24px;
    }

    .shift-hcp-name {
      color: $primary-color;
      font-size: 18px;
    }
  }

  .shift-container {
    padding: 0.25rem 2rem 0.25rem 2rem;
    .input-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 1.5rem;
      margin-bottom: 1rem;
    }

    .input-container-minor {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 1.5rem;
      margin-bottom: 1rem;
      width: 66%;
    }
  }

  .create-shift-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
