.shift-completed-view {
  label[data-shrink="false"] {
    color: gray !important;
  }

  .shift-name-action-container{
    display: flex;
    justify-content: baseline;
    align-items: baseline;
    gap: 1rem;

    h4{
        text-decoration: underline;
        letter-spacing: 0.1rem;
        cursor: pointer;
    }
}

.flex-center{
  justify-content: center;
}

.edit-timeline-container{
  display: flex;
  .edit-timeline-btn{
    justify-content: flex-end;
  }
}
  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }

  .action-btn-grp {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .attachments{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
