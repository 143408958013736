@import "src/assets/scss/common";

.shift-closed-view {
  label[data-shrink="false"] {
    color: gray !important;
  }

.shift-details h3 {
    margin-bottom: 0px !important;
    color: #808080;
    font-weight: 410;
    font-size: 15px;
}

  .MuiInputBase-inputMultiline {
    border: none !important;
  }

  .MuiOutlinedInput-multiline{
    padding: 0;
  }
  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }


  .shift-name-action-container{
    display: flex;
    justify-content: baseline;
    align-items: baseline;
    gap: 1rem;

    h4{
        text-decoration: underline;
        letter-spacing: 0.1rem;
        cursor: pointer;
    }
}

  .action-btn-grp {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .file-actions {
    color: $primary-color !important;
    margin-top: 0px;
    text-decoration: underline;
    cursor: pointer;
  }
  .file-icon {
    font-size: 6rem;
    color: #41d5c2;
  }
  .facility-details {
    justify-content: space-between;
    padding: 10px 20px;
    .button {
      text-align: end;
    }
  }
  .hcp-name-type {
    margin-left: 20px;
    justify-content: center;
    h2 {
      margin-bottom: 0px;
    }
    p {
      margin-top: 0px;
    }
  }
  .actions-wrapper {
    margin: auto;
  }
  .hcp-details {
    flex-wrap: wrap;

    h4 {
      color: $text-light;
    }
    p {
      margin-bottom: 0px;
      color: $light-black;
    }
  }
  .shift-name-requested {
    justify-content: space-between;
    .requested-on-wrapper {
      text-align: center;
      p {
        margin-top: 18px;
      }
    }
  }
  h2 {
    color: $light-black;
  }
  p {
    color: $text-light;
  }
  h3 {
    color: $light-black;
  }
  .flex-1 {
    flex: 1px;
  }

  .flex-baseline {
    align-items: baseline;
  }

  .flex-center{
		align-items: center;
		
	}
  .shift-details {
    p {
      font-size: 14px;
      color: $light-black;
    }
  }
  .attended-date {
    font-size: 16px;
    margin-top: 17px !important;
  }

  .hcp-photo-details-wrapper {
    margin: auto;
    justify-content: space-between;
    padding-bottom: 15px !important;
    .ratings {
      text-align: center;
      h4 {
        color: $text-light;
        margin-bottom: 0px;
      }
      p {
        color: $primary-color;
        font-size: 20px;
        margin-top: 0px;
      }
    }
  }

  .shift-view-actions{
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;

	}
  
  .attachments{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
