@import "src/assets/scss/common";

.remove-members-list {
	.filter {
		h2 {
			margin-top: 0px;
			color: $light-black;
		}
		p {
			margin-top: -15px;
			color: $light-black;
		}
	}
	.remove-hcps-list-table {
		.mat-td-hcp-type {
			min-width: 150px;
			max-width: 150px;
		}

		.mat-td-hcp-name {
			min-width: 250px;
			max-width: 250px;
		}
		.mat-td-actions {
			min-width: 120px;
			max-width: 120px;
      text-align: center !important;
		}
	}
	.remove-members-wrapper {
		padding-bottom: 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		.cancel {
			margin: auto;
		}
		.actions {
			margin-left: 20px;
			color: white;
		}
	}
}
